import React from "react";
import { useTranslation } from "react-i18next";
import ImageSetList, { ImageSetListProps } from "../ImageSetList";

export default function Images(props: ImageSetListProps) {
    const {t} = useTranslation();

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-10">
                <h1 className="heading-2xl">{t("images")}</h1>
                <ImageSetList {...props} />
            </div>
        </div>
    )
}