import { Form } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../form/Button";
import Input from "../form/Input";

export interface Submit {
    password: string
}

export default function ResetPassword({className, onReset}: {
    className?: string,
    onReset: (data: Submit) => Promise<void>
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>();

    return (
        <div className={classNames("flex flex-col gap-20 items-center", className)}>
            <div className="flex flex-col gap-10">
                <h1 className="heading-2xl text-center">{t("resetPassword.title")}</h1>
                <h2 className="heading-m text-center">{t("resetPassword.subtitle")}</h2>
            </div>
            <Form form={form} onSubmit={onReset} className="rounded-xl shadow-offer max-w-[480px] w-full py-8 px-5 sm:px-10 flex flex-col gap-12 bg-bg-300">
                <Input type="password" name="password" label={t("signUp.password")} placeholder={t("input.password.placeholder")} options={{
                    required: t("input.password.required"),
                    minLength: {
                        value: 8,
                        message: t("input.password.minLength")
                    }
                }} />
                <Button variant="primary" submit>{t("resetPassword.submit")}</Button>
            </Form>
        </div>
    )
}