import { Video as VideoResource } from "@/js/resources";
import { Resolution } from "@/js/types";
import React from "react";
import { useTranslation } from "react-i18next";
import { useBackground } from "../Layout";
import VideoCard, { VideoCardProps } from "../VideoCard";
import VideoCardSkeleton from "../VideoCardSkeleton";
import VideoGrid from "../VideoGrid";
import VideoOverview from "../VideoOverview";
import VideoOverviewSkeleton from "../VideoOverviewSkeleton";
import VideoPreviewCarousel from "../VideoPreviewCarousel";

export default function Video({
    video,
    moreOfModel,
    isMember,
    recommendations,
    ...props
}: {
    video?: VideoResource,
    moreOfModel?: Omit<VideoCardProps, "className">[],
    isMember: boolean,
    recommendations?: Omit<VideoCardProps, "className">[],
    playing: boolean,
    requestedResolution: Resolution | null,
    onPlay: () => Promise<void> | void,
    onChangeResolution: (resolution: Resolution) => void,
    onUpdate: (update: Partial<Pick<VideoResource, "rating" | "favorite" | "watch_later">>) => void,
    onDownload: () => void
}) {
    const {t} = useTranslation();

    useBackground(video?.featured_preview?.preview?.src ?? null);

    return (
        <div className="flex justify-center px-4 py-10 sm:py-20 overflow-hidden">
            <div className="max-w-7xl w-full flex flex-col gap-20">
                <div className="flex gap-20">
                    {video ? (
                        <VideoOverview className="flex-1" {...video} {...props} />
                    ) : (
                        <VideoOverviewSkeleton className="flex-1" />
                    )}
                    <div className="hidden lg:flex flex-col gap-8 w-[305px]">
                        <h2 className="heading-l">{t("video.moreOfModel")}</h2>
                        {moreOfModel?.slice(0, 3).map(video => (
                            <VideoCard key={video.id} {...video} />
                        )) ?? Array<void>(3).fill().map((_, index) => (
                            <VideoCardSkeleton key={index} />
                        ))}
                    </div>
                </div>
                <VideoPreviewCarousel previews={video?.previews} isMember={isMember} />
                <div className="flex flex-col gap-8">
                    <h2 className="heading-l">{t("video.recommendations")}</h2>
                    <VideoGrid>
                        {recommendations?.map(video => (
                            <VideoCard key={video.id} {...video} />
                        )) ?? Array<void>(8).fill().map((_, index) => (
                            <VideoCardSkeleton key={index} />
                        ))}
                    </VideoGrid>
                </div>
            </div>
        </div>
    )
}