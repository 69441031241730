import { Offer } from "@/js/resources";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import OfferComponent from "../Offer";
import USPs from "../USPs";

export default function SelectPlan({className, offers}: {
    className?: string,
    offers: Offer[]
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("flex flex-col items-center", className)}>
            <h1 className="heading-2xl text-center">{t("selectPlan.title")}</h1>
            <h2 className="mt-5 heading-m text-center">{t("selectPlan.subtitle")}</h2>
            <div className="mt-24 flex flex-wrap items-end gap-10 self-stretch justify-center">
                {offers.map(({id, url, ...props}) => (
                    <OfferComponent className="max-w-[400px] w-full" key={id} {...props} button={{
                        to: url,
                        children: t("selectPlan.selectPlan")
                    }} />
                ))}
            </div>
            <USPs className="mt-20" />
        </div>
    )
}