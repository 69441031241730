import { useEffect, useRef, useState } from "react";

export default function useClientSize<T extends HTMLElement>() {
    const ref = useRef<T>(null);
    const [size, setSize] = useState<{
        width: number | undefined,
        height: number | undefined
    }>(() => ({
        width: undefined,
        height: undefined
    }));

    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                setSize({
                    width: ref.current.clientWidth,
                    height: ref.current.clientHeight
                });
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [ref, setSize]);

    return [ref, size] as const;
}