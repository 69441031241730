import { VideoPreview } from "@/js/resources";
import CrownIcon from "@/svg/crown-duotone-solid.svg?react";
import classNames from "classnames";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";
import Carousel from "./Carousel";

export default function VideoPreviewCarousel({className, isMember, previews}: {
    className?: string,
    isMember?: boolean,
    previews?: VideoPreview[]
}) {
    const {t} = useTranslation();
    const refs = useRef<HTMLImageElement[]>([]);

    return (
        <div className={classNames("flex flex-col", className)}>
            <h2 className="heading-l">{t("video.previews")}</h2>
            <PhotoProvider>
                <Carousel className="mt-8" autoplay={3000} infiniteChildren={previews?.map(({id, thumbnail}) => (
                    <img key={id} draggable={false} className="w-[280px] rounded-lg aspect-video object-cover" {...thumbnail} />
                ))} itemWidth={280} gap={12} repetitions={10} infinite onItemClicked={index => refs.current[index]?.click()}>
                    {previews?.map(({id, content, thumbnail, alt}, index) => content ? (
                        <PhotoView key={id} src={content.src}>
                            <img ref={(node: HTMLImageElement) => {
                                refs.current[index] = node;
                            }} draggable={false} className="w-[280px] rounded-lg aspect-video object-cover skeleton" {...thumbnail} alt={alt} />
                        </PhotoView>
                    ) : (
                        <img draggable={false} key={id} className="w-[280px] rounded-lg aspect-video object-cover skeleton" {...thumbnail} alt={alt} />
                    )) ?? [<div key={0} className="w-[280px] rounded-lg aspect-video skeleton" />]}
                </Carousel>
            </PhotoProvider>
            {!isMember && (
                <span className="mt-5 flex items-center gap-1.5 text-text-500 text-base fill-neutral-600">
                    <CrownIcon className="w-4.5" />
                    {t("video.previews.members")}
                </span>
            )}
        </div>
    )
}