import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import Tab from "../../components/Tab";

export default function Favorites() {
    const {t} = useTranslation();
    
    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-10">
                <h1 className="heading-2xl">{t("favorites")}</h1>
                <div className="flex gap-1.5">
                    <Tab to={`/favorites/videos`}>{t("videos")}</Tab>
                    <Tab to={`/favorites/images`}>{t("images")}</Tab>
                    <Tab to={`/favorites/models`}>{t("models")}</Tab>
                </div>
                <Outlet />
            </div>
        </div>
    )
}