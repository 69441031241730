import React from "react";
import ModelList from "../../components/ModelList";
import usePaginatedModels from "../../hooks/PaginatedModelsHook";

export default function FavoriteModels() {
    const {
        models,
        loading,
        ...rest
    } = usePaginatedModels({
        filter: "favorite"
    });

    return (
        <ModelList
            items={loading ? undefined : models}
            {...rest}
        />
    )
}