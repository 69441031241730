import React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Navigate, Route, Routes } from "react-router";
import { locales } from "../types";
import NavigateToHome from "./components/NavigateToHome";
import Account from "./components/pages/account/Account";
import ScrollTop from "./components/ScrollTop";
import Gate from "./Gate";
import Layout from "./Layout";
import Password from "./pages/account/Password";
import Plan from "./pages/account/Plan";
import AllVideos from "./pages/AllVideos";
import Categories from "./pages/Categories";
import Category from "./pages/Category";
import CategoryImages from "./pages/CategoryImages";
import CategoryVideos from "./pages/CategoryVideos";
import ComingSoon from "./pages/ComingSoon";
import Contact from "./pages/Contact";
import FavoriteImageSets from "./pages/favorites/FavoriteImageSets";
import FavoriteModels from "./pages/favorites/FavoriteModels";
import Favorites from "./pages/favorites/Favorites";
import FavoriteVideos from "./pages/favorites/FavoriteVideos";
import ForgotPassword from "./pages/ForgotPassword";
import Home from "./pages/Home";
import Images from "./pages/Images";
import ImageSet from "./pages/ImageSet";
import Login from "./pages/Login";
import Model from "./pages/Model";
import Models from "./pages/Models";
import Report from "./pages/Report";
import ResetPassword from "./pages/ResetPassword";
import ImagesSearch from "./pages/search/ImagesSearch";
import ModelSearch from "./pages/search/ModelSearch";
import SearchResults from "./pages/search/SearchResults";
import VideoSearch from "./pages/search/VideoSearch";
import SelectPlan from "./pages/SelectPlan";
import SignUp from "./pages/SignUp";
import Static from "./pages/Static";
import VerifyEmail from "./pages/VerifyEmail";
import Video from "./pages/Video";
import WatchLater from "./pages/WatchLater";

const lngPath = `:lng(${locales.join("|")})`;

export default function App() {
    return (
        <BrowserRouter>
            <ScrollTop />
            <Helmet>
                <title>{import.meta.env.VITE_APP_NAME}</title>
            </Helmet>
            <Routes>
                <Route element={<Layout background="account" />}>
                    <Route element={<Gate realm="login" />}>
                        <Route path="login" element={<Login />} />
                        <Route path="login/forgot-password" element={<ForgotPassword />} />
                        <Route path="login/reset-password" element={<ResetPassword />} />
                        <Route path="sign-up" element={<SignUp />} />
                    </Route>
                    <Route element={<Gate realm="verify-email" />}>
                        <Route path="verify-email" element={<VerifyEmail />} />
                    </Route>
                    <Route element={<Gate realm="app" />}>
                        <Route path="account/select-plan" element={<SelectPlan />} />
                    </Route>
                </Route>
                {locales.map(locale => (
                    <Route key={locale} path={locale}>
                        <Route element={<Layout background="default" locale={locale} localePath />}>
                            <Route index element={<Home />} />
                            <Route path="categories" element={<Categories />} />
                            <Route path="categories/:id" element={<Category />}>
                                <Route index element={<Navigate to="videos" replace />} />
                                <Route path="videos" element={<CategoryVideos />} />
                                <Route path="images" element={<CategoryImages />} />
                            </Route>
                            <Route path="models" element={<Models />} />
                            <Route path="models/:id" element={<Model />} />
                            <Route path="videos" element={<AllVideos />} />
                            <Route path="videos/:id" element={<Video />} />
                            <Route path="images" element={<Images />} />
                            <Route path="images/:id" element={<ImageSet />} />
                            <Route path="coming-soon" element={<ComingSoon />} />
                            <Route path="search" element={<SearchResults />}>
                                <Route path="videos" element={<VideoSearch />} />
                                <Route path="images" element={<ImagesSearch />} />
                                <Route path="models" element={<ModelSearch />} />
                            </Route>
                        </Route>
                        <Route element={<Layout background="default" locale={locale} localePath noYouthProtection />}>
                            <Route path="report" element={<Report />} />
                            <Route path="tos" element={<Static slug="tos" />} />
                            <Route path="privacy" element={<Static slug="privacy" />} />
                            <Route path="2257" element={<Static slug="2257" />} />
                            <Route path="contact" element={<Contact />} />
                            <Route path="faq" element={<Static slug="faq" />} />
                        </Route>
                    </Route>
                ))}
                <Route element={<Layout background="default" />}>
                    <Route element={<Gate realm="app" />}>
                        <Route path="account" element={<Account />}>
                            <Route index element={<Navigate to="/account/password" replace />} />
                            <Route path="password" element={<Password />} />
                            <Route path="plan" element={<Plan />} />
                        </Route>
                        <Route path="watch-later" element={<WatchLater />} />
                        <Route path="favorites" element={<Favorites />}>
                            <Route index element={<Navigate to="/favorites/videos" replace />} />
                            <Route path="videos" element={<FavoriteVideos />} />
                            <Route path="images" element={<FavoriteImageSets />} />
                            <Route path="models" element={<FavoriteModels />} />
                        </Route>
                    </Route>
                </Route>
                <Route path="*" element={<NavigateToHome />} />
            </Routes>
        </BrowserRouter>
    )
}