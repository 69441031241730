import React from "react";
import { useSearchParams } from "react-router";
import ImageSetList from "../../components/ImageSetList";
import usePaginatedImageSets from "../../hooks/PaginatedImageSetsHook";

export default function ImagesSearch() {
    const [searchParams] = useSearchParams();
    const q = searchParams.get("q") ?? "";

    const {imageSets, ...rest} = usePaginatedImageSets({q}, [q]);

    return (
        <ImageSetList items={imageSets} {...rest} />
    )
}