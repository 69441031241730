import { useUser } from "@/js/providers/UserProvider";
import { useImageSets } from "@/js/resources";
import { Params } from "@enymo/react-resource-hook";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { useSanitizedNumber, useSanitizedString } from "../../admin/hooks/SanitizedParamHook";
import { SortBy } from "../components/ImageSetList";
import useBreakpoint from "./BreakpointHook";

export default function usePaginatedImageSets(params: Params = {}, dependencies: React.DependencyList = []) {
    const {i18n} = useTranslation();
    const {user} = useUser();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = useSanitizedNumber(searchParams.get("page"), {min: 1}, 1);
    const sortBy = useSanitizedString(searchParams.get("sort-by"), ["newest", "best_rated"], "newest");

    const lg = useBreakpoint("lg");
    const sm = useBreakpoint("sm");
    const itemsPerPage = lg ? 30 : sm ? 20 : 10;

    const [imageSets, {update, loading, meta}] = useImageSets({params: useMemo(() => ({
        locale: i18n.language,
        with: ["previews", "featured_model", "rating"],
        per_page: itemsPerPage,
        page,
        sort_by: (() => {
            switch (sortBy) {
                case "best_rated":
                    return {name: "likes", direction: "desc"};
                case "newest":
                    return {name: "publish_at", direction: "desc"};
            } 
        })(),
        ...params
    }), [i18n.language, page, itemsPerPage, sortBy, ...dependencies])});

    const handleUpdate = (id: number, data: {favorite?: boolean}) => {
        if (user === null) {
            navigate("/login");
        }
        else {
            console.log("upd");
            update(id, data, "immediate");
        }
    }

    if (meta?.last_page && meta.last_page < page) {
        searchParams.set("page", meta.last_page.toString());
        setSearchParams(searchParams);
    }

    const onChangeSortBy = (sortBy: SortBy) => {
        searchParams.set("sort-by", sortBy);
        setSearchParams(searchParams);
    }

    const onChangePage = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    }

    return {imageSets, onUpdate: handleUpdate, loading, totalItems: meta?.total, itemsPerPage, sortBy, page, onChangePage, onChangeSortBy}
}