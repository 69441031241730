import { ChoiceProps } from "@/js/types";
import { useGlissadeChoice } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";
import Radio from "../Radio";

export default function RadioInput({className, children, ...props}: ChoiceProps) {
    const {error, ...glissade} = useGlissadeChoice(props);

    return (
        <label className={classNames("flex gap-2 items-center body-m cursor-pointer select-none", className)}>
            <input className="absolute size-0 peer" type="radio" {...glissade} />
            <Radio error={error !== undefined} />
            {children}
        </label>
    )
}