import { ImageSet as ImageSetResource } from "@/js/resources";
import CrownIcon from "@/svg/crown-duotone-solid.svg?react";
import React from "react";
import { useTranslation } from "react-i18next";
import { PhotoProvider, PhotoView } from "react-photo-view";
import ImageSetCard, { ImageSetCardProps } from "../ImageSetCard";
import ImageSetCardSkeleton from "../ImageSetCardSkeleton";
import ImageSetOverview from "../ImageSetOverview";
import ImageSetOverviewSkeleton from "../ImageSetOverviewSkeleton";
import { useBackground } from "../Layout";
import Repeat from "../Repeat";

export default function ImageSet({imageSet, onUpdate, onDownload, recommendations, isMember}: {
    imageSet?: ImageSetResource,
    recommendations?: ImageSetCardProps[],
    isMember?: boolean,
    onUpdate: (update: Partial<Pick<ImageSetResource, "rating" | "favorite" | "view">>) => void,
    onDownload: () => void
}) {
    const {t} = useTranslation();

    useBackground(imageSet?.previews?.[0]?.preview?.src ?? null);

    const handleView = () => {
        if (!imageSet?.view) {
            onUpdate({view: true});
        }
    }

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-20">
                <div className="flex gap-16">
                    {imageSet ? (
                        <ImageSetOverview className="flex-1" onUpdate={onUpdate} onDownload={onDownload} {...imageSet} />
                    ) : (
                        <ImageSetOverviewSkeleton className="flex-1" />
                    )}
                    <div className="w-[370px] hidden lg:flex flex-col gap-8">
                        <h2 className="heading-l">{t("imageSet.recommendations")}</h2>
                        {recommendations?.slice(0, 3).map(item => (
                            <ImageSetCard key={item.id} {...item} />
                        )) ?? <Repeat element={ImageSetCardSkeleton} count={3} />}
                    </div>
                </div>
                <div className="flex flex-col gap-9">
                    <div className="flex items-center gap-6">
                        <h2 className="heading-l">{t("imageSet.allImages")}</h2>
                        {!isMember && (
                            <div className="flex gap-1.5 items-center body-l-md fill-white">
                                <CrownIcon className="w-4.5" />
                                {t("imageSet.membersOnly")}
                            </div>
                        )}
                    </div>
                    <PhotoProvider>
                        <div className="grid grid-cols-[repeat(auto-fill,minmax(246px,1fr))] gap-3">
                            {imageSet?.images?.map(({id, thumbnail, content, alt}) => content ? (
                                <PhotoView key={id} src={content.src} width={content.width} height={content.height}>
                                    <img onClick={handleView} className="aspect-image rounded-md skeleton w-full" src={thumbnail.src} width={thumbnail.width} height={thumbnail.height} alt={alt} />
                                </PhotoView>
                            ) : (
                                <img key={id} className="aspect-image rounded-md skeleton w-full" src={thumbnail.src} width={thumbnail.width} height={thumbnail.height} alt={alt} />
                            )) ?? <Repeat element={() => <div className="aspect-image rounded-md skeleton w-full" />} count={10} />}
                        </div>
                    </PhotoProvider>
                </div>
            </div>
        </div>
    )
}