import React from "react";
import { HelmetProvider } from "react-helmet-async";
import CommonProvider from "../providers/CommonProvider";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import ErrorHandler from "./ErrorHandler";
import ToasterProvider from "./providers/ToasterProvider";

export default function Bootstrapper() {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <HelmetProvider>
                    <CommonProvider>
                        <ToasterProvider>
                            <App />
                            <ErrorHandler />
                        </ToasterProvider>
                    </CommonProvider>
                </HelmetProvider>
            </ErrorBoundary>
        </React.StrictMode>
    )
}