import { useUser } from "@/js/providers/UserProvider";
import React from "react";
import { useTranslation } from "react-i18next";
import VerifyEmailComponent from "../components/pages/VerifyEmail";
import { useToaster } from "../providers/ToasterProvider";

export default function VerifyEmail() {
    const {t} = useTranslation();
    const toast = useToaster();
    const {requestVerifyEmail} = useUser();

    const handleSendAgain = async () => {
        try {
            await requestVerifyEmail();
            toast({
                title: t("verifyEmail.sent"),
                text: t("verifyEmail.sent.text"),
                variant: "success",
                duration: 3000
            });
        }
        catch {
            toast({
                title: t("verifyEmail.error"),
                text: t("verifyEmail.error.text"),
                variant: "error",
                duration: 3000
            });
        }
    }

    return (
        <VerifyEmailComponent
            className="py-52 max-w-[600px] self-center"
            onSendAgain={handleSendAgain}
        />
    )
}