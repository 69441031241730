import { Offer as OfferResource } from "@/js/resources";
import CircleCheck from "@/svg/circle-check-solid.svg?react";
import CircleXMark from "@/svg/circle-xmark-solid.svg?react";
import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "./form/Button";

interface OfferProps extends Omit<OfferResource, "id"> {
    className?: string,
    button?: {
        to?: string,
        onClick?: () => Promise<void>,
        children: React.ReactNode
    }
}

export default function Offer({
    className,
    period,
    highlight,
    price,
    trial_days,
    trial_price,
    features,
    button
}: OfferProps) {
    const {t, i18n} = useTranslation();

    return (
        <div className={classNames("rounded-xl bg-bg-900/70 shadow-offer flex flex-col overflow-hidden border-primary-900", {
            "border-2": highlight
        }, className)}>
            {highlight && (
                <div className="h-12 flex items-center justify-center bg-primary-900 text-primary-500 font-medium text-2xl">
                    {t("offer.highlight")}
                </div>
            )}
            <div className="flex flex-col items-center justify-center p-10 font-medium text-xl text-neutral-800">
                <span className="flex items-end gap-3 whitespace-nowrap">
                    <Trans i18nKey={trial_days && trial_price ? "offer.trial" : "offer.monthlyPrice"} count={trial_days}>
                        <span className="font-bold text-7xl text-white leading-none">€ {{price: ((trial_price ?? (() => {
                            switch (period) {
                                case "monthly":
                                    return price;
                                case "yearly":
                                    return price / 12;
                                case "biyearly":
                                    return price / 24;
                            }
                        })()) / 100).toLocaleString(i18n.language, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} as any}</span>
                        {trial_days && trial_price ? <>for {{count: trial_days} as any} days</> : <>per month</>}
                    </Trans>
                </span>
                <span className="mt-4 text-center">
                    {t(`offer.${period}${trial_price && trial_days ? "AfterTrial" : ""}`, {price: (price / 100).toLocaleString(i18n.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })})}
                </span>
                {features && (
                    <div className={classNames("flex items-center gap-2 body-l-md mt-4", features.includes("downloads") ? "text-text-800 fill-white" : "text-text-600 fill-neutral-800")}>
                        {features.includes("downloads") ? <>
                            <CircleCheck className="w-5" />
                            {t("features.download")}
                        </> : <>
                            <CircleXMark className="w-5" />
                            {t("features.noDownload")}
                        </>}
                    </div>
                )}
                {button && (
                    <Button linkType="no-router" variant={highlight ? "primary" : "secondary"} className="mt-10 self-stretch" {...button} />
                )}
            </div>
        </div>
    )
}