import React from "react";
import ImagesComponent from "../components/pages/Images";
import usePaginatedImageSets from "../hooks/PaginatedImageSetsHook";

export default function Images() {
   
    const {
        imageSets,
        loading,
        ...rest
    } = usePaginatedImageSets();

    return (
        <ImagesComponent
            items={loading ? undefined : imageSets}
            {...rest}
        />  
    )
}