import React from "react";
import { useSearchParams } from "react-router";
import ModelList from "../../components/ModelList";
import usePaginatedModels from "../../hooks/PaginatedModelsHook";

export default function ModelSearch() {
    const [searchParams] = useSearchParams();
    const q = searchParams.get("q") ?? "";

    const {models, ...rest} = usePaginatedModels({q}, [q]);

    return (
        <ModelList items={models} {...rest} />
    )
}