import { Model as ModelResource } from "@/js/resources";
import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useSearchParams } from "react-router";
import ImageSetCard from "../ImageSetCard";
import ImageSetCardSkeleton from "../ImageSetCardSkeleton";
import ImageSetGrid from "../ImageSetGrid";
import { useBackground } from "../Layout";
import ModelCard, { ModelCardProps } from "../ModelCard";
import ModelOverview from "../ModelOverview";
import ModelOverviewSkeleton from "../ModelOverviewSkeleton";
import Repeat from "../Repeat";
import Tab from "../Tab";
import VideoCard from "../VideoCard";
import VideoCardSkeleton from "../VideoCardSkeleton";
import VideoGrid from "../VideoGrid";

export default function Model({model, onUpdate, more}: {
    model?: ModelResource,
    more?: Omit<ModelCardProps, "className">[]
    onUpdate: (update: Partial<Pick<ModelResource, "rating" | "favorite">>) => void
}) {
    const location = useLocation();
    const {t} = useTranslation();
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type");

    console.log(model?.videos);

    useBackground(model?.preview?.src ?? null);
    
    if (type === null) {
        return <Navigate to={`${location.pathname}?type=videos`} replace />
    }

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-20">
                <div className="flex gap-20">
                    {model ? (
                        <ModelOverview className="flex-1" onUpdate={onUpdate} {...model} />
                    ) : (
                        <ModelOverviewSkeleton className="flex-1" />
                    )}
                    <div className="hidden lg:flex w-[305px] flex-col gap-8">
                        <h2 className="heading-l">{t("model.more")}</h2>
                        {more?.slice(0, 4).map(item => (
                            <ModelCard key={item.id} {...item} />
                        )) ?? <Repeat element={() => <div className="aspect-card rounded-lg skeleton" />} count={4} />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <h2 className="heading-l">{t("model.content")}</h2>
                    <div className="flex gap-1.5 mt-6">
                        <Tab to={`${location.pathname}?type=videos`}>{t("videos")}</Tab>
                        <Tab to={`${location.pathname}?type=images`}>{t("images")}</Tab>
                    </div>
                    {type === "videos" ? (
                        <VideoGrid className="mt-8">
                            {model?.videos?.map(item => (
                                <VideoCard key={item.id} {...item} favorite={false} watch_later={false} featured_model={model} />
                            )) ?? <Repeat element={VideoCardSkeleton} count={8} />}
                        </VideoGrid>
                    ) : (
                        <ImageSetGrid className="mt-8">
                            {model?.imageSets?.map(item => (
                                <ImageSetCard key={item.id} {...item} favorite={false} featured_model={model} />
                            )) ?? <Repeat element={ImageSetCardSkeleton} count={6} />}
                        </ImageSetGrid>
                    )}
                </div>
            </div>
        </div>
    )
}