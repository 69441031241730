import { locales } from "@/js/types";
import ChevronLeft from "@/svg/chevron-left-regular.svg?react";
import ChevronRight from "@/svg/chevron-right-regular.svg?react";
import CircleQuestionIcon from "@/svg/circle-question-regular.svg?react";
import CircleUserIcon from "@/svg/circle-user-solid.svg?react";
import ClockIcon from "@/svg/clock-regular.svg?react";
import CrownIcon from "@/svg/crown-duotone-solid.svg?react";
import GlobeIcon from "@/svg/globe-regular.svg?react";
import HeadsetIcon from "@/svg/headset-solid.svg?react";
import HeartIcon from "@/svg/heart-regular.svg?react";
import RightFromBracketIcon from "@/svg/right-from-bracket-solid.svg?react";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

export function UserMenuSection({children}: {
    children: React.ReactNode
}) {
    return (
        <div className="flex flex-col gap-1 px-2 py-2.5 border-neutral-200 border-t first:border-t-0">
            {children}
        </div>
    )
}

export interface UserMenuItemProps extends ClickableProps {
    icon?: React.ReactNode
    active?: boolean
}

export function UserMenuItem({
    className,
    icon,
    active: activeProp,
    to,
    children,
    ...props
}: UserMenuItemProps) {
    const location = useLocation();
    const active = activeProp ?? (to && location.pathname.startsWith(to))

    return (
        <Clickable to={to} className={classNames("h-8 rounded-md hover:bg-bg-800 px-3 body-m-md flex items-center gap-3", {
            "text-primary-500": active
        }, className)} {...props}>
            {icon && (
                <div className="flex justify-center w-5 fill-neutral-800">
                    {icon}
                </div>
            )}
            {children}
        </Clickable>
    )
}

export function UserMenuLanguageItem({children, ...props}: Omit<ClickableProps, "className">) {
    const {i18n} = useTranslation();

    return (
        <UserMenuItem icon={<GlobeIcon className="w-5" />} {...props}>
            <div className="flex justify-between items-center">
                {children}
                <div className="grow flex items-center gap-1.5 text-text-600 uppercase">
                    {i18n.language}
                    <ChevronRight className="size-3 fill-neutral-900" />
                </div>
            </div>
        </UserMenuItem>
    )
}

export function UserMenuFrame({hidden, children}: {
    hidden?: boolean,
    children: React.ReactNode
}) {
    return (
        <div className={`w-60 rounded-xl shadow-dropdown bg-bg-300 ${hidden ? "hidden" : "flex"} flex-col`}>
            {children}
        </div>
    )
}

export type UserMenuAction = Pick<UserMenuItemProps, "to" | "onClick" | "active">

export default function UserMenu({
    loggedIn = false,
    isMember = false,
    localePath = false,
    onLogout
}: {
    loggedIn?: boolean,
    isMember?: boolean,
    onLogout?: () => void,
    localePath?: boolean
}) {
    const {t, i18n} = useTranslation();
    const lng = i18n.language;
    const [changeLanguage, setChangeLanguage] = useState(false);
    const location = useLocation();

    const handleChangeLanguage = (locale: string) => () => {
        i18n.changeLanguage(locale);
        setChangeLanguage(false);
    }

    return <>
        <UserMenuFrame hidden={!changeLanguage}>
            <UserMenuSection>
                <button type="button" onClick={() => setChangeLanguage(false)} className="self-start flex items-center mt-0.5 mb-2 mx-4 body-m-md text-text-600 fill-neutral-700 gap-1.5 leading-none">
                    <ChevronLeft className="w-2.5" />
                    {t("back")}
                </button>
                {locales.map(locale => (
                    <UserMenuItem key={locale} to={localePath ? `/${locale}${location.pathname.substring(lng.length + 1)}` : undefined} active={i18n.language === locale} onClick={handleChangeLanguage(locale)}>
                        {t(`locale.${locale}`)}
                    </UserMenuItem>
                ))}
            </UserMenuSection>
        </UserMenuFrame>
        <UserMenuFrame hidden={changeLanguage}>
            {loggedIn ? <>
                <UserMenuSection>
                    <UserMenuItem to="/account" icon={<CircleUserIcon className="w-5" />}>
                        {t("user.account")}
                    </UserMenuItem>
                    {!isMember && (
                        <UserMenuItem className="md:hidden" to="/account/select-plan" icon={<CrownIcon className="w-5" />}>
                            {t("user.becomeMember")}
                        </UserMenuItem>
                    )}
                    <UserMenuItem onClick={onLogout} icon={<RightFromBracketIcon className="w-4.5" />}>
                        {t("user.logout")}
                    </UserMenuItem>
                </UserMenuSection>
                <UserMenuSection>
                    <UserMenuItem to="/watch-later" icon={<ClockIcon className="w-5" />}>
                        {t("user.watchLater")}
                    </UserMenuItem>
                    <UserMenuItem to="/favorites" icon={<HeartIcon className="w-5" />}>
                        {t("user.favorites")}
                    </UserMenuItem>
                </UserMenuSection>
            </> : (
                <UserMenuSection>
                    <UserMenuItem to="/login" icon={<CircleUserIcon className="w-5" />}>
                        {t("user.login")}
                    </UserMenuItem>
                    {!isMember && (
                        <UserMenuItem className="md:hidden" to={`/account/select-plan`} icon={<CrownIcon className="w-5" />}>
                            {t("user.becomeMember")}
                        </UserMenuItem>
                    )}
                </UserMenuSection>
            )}
            <UserMenuSection>
                <UserMenuItem icon={<GlobeIcon className="w-5" />} onClick={() => setChangeLanguage(true)}>
                    <div className="grow flex justify-between items-center">
                        {t("user.language")}
                        <div className="flex items-center gap-1.5 text-text-600 uppercase">
                            {i18n.language}
                            <ChevronRight className="size-3 fill-neutral-900" />
                        </div>
                    </div>
                </UserMenuItem>
                <UserMenuItem to={`/${lng}/faq`} icon={<CircleQuestionIcon className="w-5" />}>
                    {t("user.faq")}
                </UserMenuItem>
                <UserMenuItem to={`/${lng}/contact`} icon={<HeadsetIcon className="w-5" />}>
                    {t("user.support")}
                </UserMenuItem>
            </UserMenuSection>
        </UserMenuFrame>
    </>
}