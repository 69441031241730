import classNames from "classnames";
import React from "react";

export default function ImageSetCardSkeleton({className}: {
    className?: string
}) {
    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="flex gap-2">
                <div className="flex-2 rounded-lg aspect-image skeleton" />
                <div className="flex flex-col flex-1 gap-1.5">
                    <div className="flex-1 rounded-md skeleton" />
                    <div className="flex-1 rounded-md skeleton" />
                </div>
            </div>
            <div className="mt-3 mx-1.5 rounded-sm skeleton h-4.5 max-w-[330px]" />
            <div className="mt-2 mx-1.5 flex items-center gap-4 justify-between">
                <div className="flex flex-1 items-center gap-2">
                    <div className="size-5 rounded-full skeleton" />
                    <div className="flex-1 rounded-sm h-3.5 max-w-[240px] skeleton" />
                </div>
                <div className="rounded-sm h-3.5 w-[90px] skeleton" />
            </div>
        </div>
    )
}