import React from "react";
import ModelsComponent from "../components/pages/Models";
import usePaginatedModels from "../hooks/PaginatedModelsHook";

export default function Models() {
    const {
        models,
        itemsPerPage,
        page,
        onChangePage,
        onChangeSortBy,
        loading,
        sortBy,
        totalItems
    } = usePaginatedModels();

    return (
        <ModelsComponent
            itemsPerPage={itemsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeSortBy={onChangeSortBy}
            sortBy={sortBy}
            items={loading ? undefined : models}
            totalItems={totalItems}
        />
    )
}