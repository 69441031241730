import { useError } from "@enymo/glissade";
import React from "react";
import Error from "./Error";

export default function ContextError({className}: {
    className?: string
}) {
    const error = useError();

    return <Error className={className}>{error}</Error>
}