import { ImageSet } from "@/js/resources";
import DownloadIcon from "@/svg/download-regular.svg?react";
import FlagIcon from "@/svg/flag-regular.svg?react";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Actions from "./Actions";
import { DotsDropdownItem } from "./DotsDropdown";
import Link from "./Link";
import OverviewInfo from "./OverviewInfo";

export interface ImageSetOverviewProps extends Omit<ImageSet, "publish_at"> {
    className?: string,
    onUpdate: (update: Partial<Pick<ImageSet, "rating" | "favorite">>) => void,
    onDownload: () => void
}

export default function ImageSetOverview({
    className,
    id,
    title,
    previews,
    description,
    images_count,
    likes,
    dislikes,
    rating,
    favorite,
    models,
    category_id,
    category,
    downloadable,
    has_download,
    onDownload,
    onUpdate
}: ImageSetOverviewProps) {
    assertNotNull(previews, "relation 'previews' must be loaded");
    assertNotNull(models, "relation 'models' must be loaded");
    assertNotNull(category, "relation 'category must be loaded");

    const {t, i18n: {language: lng}} = useTranslation();

    const reportUrl = useMemo(() => {
            const searchParams = new URLSearchParams;
            searchParams.set("url", `images/${id}`);
            return `/${lng}/report?${searchParams.toString()}`;
        }, [id]);

    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="flex flex-col gap-8">
                <h1 className="heading-xl">{title}</h1>
                <div className="flex aspect-video gap-1.5">
                    <div className="flex-3 flex flex-col gap-1.5">
                        <img className="flex-2 rounded-md object-cover skeleton w-auto" {...previews[0]?.preview} alt={previews[0]?.alt} id={undefined} />
                        <div className="flex-1 flex gap-2">
                            {previews.slice(1, 4).map(({id, preview, alt}) => (
                                <img key={id} className="flex-1 rounded-md object-cover skeleton w-auto" {...preview} alt={alt} />
                            ))}
                        </div>
                    </div>
                    <div className="flex-2 flex flex-col gap-2.5">
                        {previews.slice(4, 6).map(({id, thumbnail, alt}) => (
                            <img key={id} className="flex-1 rounded-md object-cover skeleton w-auto" {...thumbnail} alt={alt} />
                        ))}
                    </div>
                </div>
                <Actions
                    liked={rating === "like"}
                    disliked={rating === "dislike"}
                    likes={likes}
                    dislikes={dislikes}
                    onLike={() => onUpdate({rating: rating === "like" ? null : "like"})}
                    onDislike={() => onUpdate({rating: rating === "dislike" ? null : "dislike"})}
                    favorite={favorite}
                    onFavorite={() => onUpdate({favorite: !favorite})}
                    dropdownItems={[...(has_download ? [{
                        icon: <DownloadIcon className="w-4" />,
                        onClick: onDownload,
                        variant: downloadable ? "normal" : "disabled",
                        children: t("download")
                    } as DotsDropdownItem] : []), {
                        icon: <FlagIcon className="w-3.5" />,
                        to: reportUrl,
                        children: t("report")
                    }]}
                />
            </div>
            <p className="mt-14 mb-10 body-m">{description}</p>
            <div className="self-start grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-4">
                <OverviewInfo style={{gridRow: `span ${models.length}`}} title={t("imageSet.models")}>
                    <div className="flex flex-col gap-4">
                        {models.map(({id, first_name, age, avatar}) => (
                            <div key={id} className="flex gap-1.5 items-center">
                                <img className="size-5 rounded-full" {...avatar} id={undefined} />
                                <Link className="body-m" to={`/${lng}/models/${id}`}>{first_name} ({age})</Link>
                            </div>
                        ))}
                    </div>
                </OverviewInfo>
                <OverviewInfo title={t("imageSet.category")}>
                    <Link className="body-m" to={`/${lng}/categories/${category_id}`}>{category.title}</Link>
                </OverviewInfo>
                <OverviewInfo title={t("imageSet.imagesCount")}>{images_count}</OverviewInfo>
            </div>
        </div>
    )
}