import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { addSentryListener, removeSentryListener } from "../sentry";
import { useToaster } from "./providers/ToasterProvider";

export default function ErrorHandler() {
    const {t} = useTranslation();
    const toast = useToaster();
    useEffect(() => {
        const handleSentryEvent = () => {
            toast({
                title: t("errorHandler.title"),
                text: t("errorHandler.text"),
                duration: 5000,
                variant: "error"
            });
        };
        addSentryListener(handleSentryEvent);
        return () => removeSentryListener(handleSentryEvent);
    }, [t, toast]);

    return null;
}