import { useUser } from "@/js/providers/UserProvider";
import React from "react";
import { route } from "ziggy-js";
import PlanComponent from "../../components/pages/account/Plan";



export default function Plan() {
    const {user} = useUser();

    return (
        <PlanComponent
            className="w-xl"
            role={user?.role ?? "user"}
            currentPlan={user?.plan ?? null}
            toChangePaymentMethod={route("subscriptions.update")}
            nextSubscription={user?.next_subscription ?? null}
        />
    )
}