import { ListProps } from "@/js/types";
import React from "react";
import { useTranslation } from "react-i18next";
import ModelCard, { ModelCardProps } from "./ModelCard";
import ModelGrid from "./ModelGrid";
import PaginatedList from "./PaginatedList";

export type SortBy = "newest" | "alphabetic" | "best_rated";
export type ModelListProps = ListProps<SortBy, ModelCardProps>;

export default function ModelList({
    itemsPerPage,
    page,
    totalItems,
    items,
    ...props
}: ModelListProps) {
    const {t} = useTranslation();
    const totalPages = totalItems !== undefined ? Math.ceil(totalItems / itemsPerPage) : undefined;

    return ( 
        <PaginatedList totalPages={totalPages} page={page} sortByChoices={[{
            value: "newest",
            label: t("sortBy.newest")
        }, {
            value: "alphabetic",
            label: t("sortBy.alphabetic")
        }, {
            value: "best_rated",
            label: t("sortBy.bestRated")
        }]} {...props}>
            <ModelGrid>
                {items?.map(item => (
                    <ModelCard key={item.id} {...item} />
                )) ?? Array<void>(page === totalPages ? ((totalItems ?? 0) % itemsPerPage) || itemsPerPage : itemsPerPage).fill().map((_, index) => (
                    <div key={index} className="aspect-card rounded-lg skeleton" />
                ))}
            </ModelGrid>
        </PaginatedList>
    )
}