import { Image } from "@/js/resources"
import React from "react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import BannerCarousel from "../BannerCarousel"
import FeaturedCategories, { FeaturedCategoryProps } from "../FeaturedCategories"
import FeaturedModelCarousel from "../FeaturedModelCarousel"
import Button from "../form/Button"
import { ModelCardProps } from "../ModelCard"
import VideoCard, { VideoCardProps } from "../VideoCard"
import VideoCardSkeleton from "../VideoCardSkeleton"
import VideoGrid from "../VideoGrid"

export default function Home({
    topBannerImages,
    bottomBannerImages,
    latestVideos,
    featuredCategories,
    popularVideos,
    featuredModels,
    onUpdateLatestVideo,
    onUpdatePopularVideo
}: {
    topBannerImages?: Image[],
    bottomBannerImages?: Image[],
    latestVideos?: Omit<VideoCardProps, "onUpdate">[],
    featuredCategories?: FeaturedCategoryProps[],
    popularVideos?: Omit<VideoCardProps, "onUpdate">[],
    featuredModels?: ModelCardProps[],
    onUpdateLatestVideo: (id: number, data: {favorite?: boolean, watch_later?: boolean}) => void,
    onUpdatePopularVideo: (id: number, data: {favorite?: boolean, watch_later?: boolean}) => void
}) {
    const {t, i18n: {language: lng}} = useTranslation();

    return (
        <div className="flex flex-col items-center overflow-hidden px-4 py-11 lg:py-20 gap-11 lg:gap-20">
            <Helmet>
                <title>{t("home.title")}</title>
                <meta name="description" content={t("home.description")} />
            </Helmet>
            <div className="max-w-7xl w-full flex flex-col gap-11 lg:gap-20">
                <BannerCarousel autoplay={topBannerImages && 3000}>
                    {topBannerImages?.map(({id, ...props}) => (
                        <img draggable={false} className="rounded-lg w-full skeleton h-[78px] sm:h-[166px] lg:h-[260px] object-cover" key={id} {...props} />
                    )) ?? [<div key={0} className="rounded-lg w-full skeleton h-[78px] sm:h-[166px] lg:h-[260px]" />]}
                </BannerCarousel>
                <div className="flex flex-col gap-6">
                    <div className="flex justify-between">
                        <h2 className="heading-l">{t("home.latestVideos")}</h2>
                        <Button variant="transparent" to={`/${lng}/videos`}>{t("home.videos.showAll")}</Button>
                    </div>
                    <VideoGrid>
                        {latestVideos?.slice(0, 8).map((video, index) => (
                            <VideoCard key={video.id} onUpdate={data => onUpdateLatestVideo(video.id, data)} {...video} className={index > 5 ? "hidden lg:flex" : index > 3 ? "hidden sm:flex" : undefined} />
                        )) ?? Array<void>(8).fill().map((_, index) => (
                            <VideoCardSkeleton key={index} className={index > 5 ? "hidden lg:flex" : index > 3 ? "hidden sm:flex" : undefined} />
                        ))}
                    </VideoGrid>
                </div>
                <div className="flex flex-col gap-6">
                    <div className="flex justify-between">
                        <h2 className="heading-l">{t("home.featuredCategories")}</h2>
                        <Button variant="transparent" to={`/${lng}/categories`}>{t("home.featuredCategories.showAll")}</Button>
                    </div>
                    <FeaturedCategories categories={featuredCategories} />
                </div>
                <div className="flex flex-col gap-6">
                    <div className="flex justify-between">
                        <h2 className="heading-l">{t("home.popularVideos")}</h2>
                        <Button variant="transparent" to={`/${lng}/videos`}>{t("home.videos.showAll")}</Button>
                    </div>
                    <VideoGrid>
                        {popularVideos?.slice(0, 16).map((video, index) => (
                            <VideoCard key={video.id} onUpdate={data => onUpdatePopularVideo(video.id, data)} {...video} className={index > 5 ? "hidden lg:flex" : index > 3 ? "hidden sm:flex" : undefined} />
                        )) ?? Array<void>(16).fill().map((_, index) => (
                            <VideoCardSkeleton key={index} className={index > 5 ? "hidden lg:flex" : index > 3 ? "hidden sm:flex" : undefined} />
                        ))}
                    </VideoGrid>
                </div>
            </div>
            <div className="flex flex-col gap-6 self-stretch">
                <div className="flex self-center max-w-7xl w-full justify-between">
                    <h2 className="heading-l">{t("home.topModels")}</h2>
                    <Button variant="transparent" to={`/${lng}/models`}>{t("home.models.showAll")}</Button>
                </div>
                <FeaturedModelCarousel models={featuredModels} />
            </div>
            <BannerCarousel className="max-w-7xl w-full mt-4 lg:mt-24" autoplay={bottomBannerImages && 3000}>
                {bottomBannerImages?.map(({id, ...props}) => (
                    <img draggable={false} className="rounded-lg w-full skeleton h-[78px] sm:h-[166px] lg:h-[260px] object-cover" key={id} {...props} />
                )) ?? [<div key={0} className="rounded-lg w-full skeleton h-[78px] sm:h-[166px] lg:h-[260px]" />]}
            </BannerCarousel>
        </div>
    )
}