import React from "react";
import { useSearchParams } from "react-router";
import VideoList from "../../components/VideoList";
import usePaginatedVideos from "../../hooks/PaginatedVideosHook";

export default function VideoSearch() {
    const [searchParams] = useSearchParams();
    const q = searchParams.get("q") ?? "";

    const {
        videos,
        loading,
        ...rest
    } = usePaginatedVideos({q}, [q]);

    return (
        <VideoList items={loading ? undefined : videos} {...rest} />
    )
}