import React from "react";
import { useParams } from "react-router";
import VideoList from "../components/VideoList";
import usePaginatedVideos from "../hooks/PaginatedVideosHook";

export default function CategoryVideos() {
    const id = Number(useParams().id);

    const {
        videos,
        loading,
        ...rest
    } = usePaginatedVideos({
        category: id
    }, [id]);
    

    return (
        <VideoList
            items={loading ? undefined : videos}
            {...rest}
        />
    )
}