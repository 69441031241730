import { useDayjs } from "@/js/providers/DayjsProvider";
import { Plan as PlanResource, Role, User } from "@/js/resources";
import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../form/Button";

export default function Plan({
    className,
    currentPlan,
    role,
    toChangePaymentMethod,
    nextSubscription
}: {
    className?: string,
    currentPlan: Pick<PlanResource, "period" | "price"> | null,
    role: Role,
    toChangePaymentMethod: string,
    nextSubscription: User["next_subscription"]
}) {
    const {t, i18n} = useTranslation();
    const dayjs = useDayjs();

    return (
        <div className={classNames("flex flex-col gap-6 md:gap-14 max-w-full", className)}>
            <h1 className="heading-l">{t("account.plan")}</h1>
            <div className="rounded-xl bg-bg-700 p-6 flex sm:flex-row flex-col items-center justify-between gap-14">
                <div className="flex flex-col gap-5">
                    <h3 className="body-l-md">{t("account.plan.currentPlan")}</h3>
                    {role === "admin" ? (
                        <span className="text-medium text-base text-text-500">{t("account.plan.admin")}</span>
                    ) : role === "permanentAccess" ? (
                        <span className="text-medium text-base text-text-500">{t("account.plan.permanentAccess")}</span>
                    ) : currentPlan ? (
                        <div className="flex flex-col gap-3 font-medium text-base text-neutral-800">
                            <span className="flex items-end gap-2.5">
                                <Trans i18nKey="offer.monthlyPrice">
                                    <span className="text-bold text-4xl text-white leading-none">$ {{price: (currentPlan.price / 100).toLocaleString(i18n.language, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })} as any}</span>
                                    per Month
                                </Trans>
                            </span>
                            <span>
                                {(() => {
                                    switch (currentPlan.period) {
                                        case "monthly":
                                            return t("offer.monthly");
                                        case "yearly":
                                            return t("offer.yearly", {price: (currentPlan.price * 12 / 100).toLocaleString(i18n.language, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })});
                                        /*
                                        case "biyearly":
                                            return t("offer.biyearly", {price: (currentPlan.price * 24 / 100).toLocaleString(i18n.language, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2
                                            })});
                                        */
                                    }
                                })()}
                            </span>
                            {nextSubscription !== null && (
                                <span>
                                    {t(nextSubscription.plan === null ? "account.plan.cancelled" : "account.plan.downgrade", {date: dayjs(nextSubscription.valid_from).format("L")})}
                                </span>
                            )}
                        </div>
                    ) : (
                        <span className="text-base text-text-500">{t("account.plan.noPlan")}</span>
                    )}
                </div>
                {role === "user" && (
                    <div className="flex flex-col gap-4 max-w-[230px] w-full">
                        <Button variant="primary" to="/account/select-plan" disabled={nextSubscription !== null}>{currentPlan ? t("account.plan.change") : t("account.plan.select")}</Button>
                        {currentPlan && <Button variant="secondary" linkType="no-router" to={toChangePaymentMethod}>{t("account.plan.changePaymentMethod")}</Button>}
                    </div>
                )}
            </div>
        </div>
    )
}