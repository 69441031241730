import React from "react";
import { useTranslation } from "react-i18next";
import AllVideosComponent from "../components/pages/AllVideos";
import usePaginatedVideos from "../hooks/PaginatedVideosHook";

export default function AllVideos() {
    const {i18n} = useTranslation();

    const {
        videos,
        loading,
        ...rest
    } = usePaginatedVideos();
    return (
        <AllVideosComponent
            {...rest}
            items={loading ? undefined : videos}
        />
    )
}