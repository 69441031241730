import { useUser } from "@/js/providers/UserProvider";
import { SubmitHandler } from "@enymo/react-form-component";
import React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router";
import ResetPasswordComponent, { Submit } from "../components/pages/ResetPassword";

export default function ResetPassword() {
    const {login} = useUser();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const email = searchParams.get("email");

    if (token === null || email === null) {
        return <Navigate to="/login/forgot-password" replace />
    }

    const handleSubmit: SubmitHandler<Submit> = async ({password}) => {
        await login(email, password, false, token);
        navigate("/");
    }
    
    return (
        <ResetPasswordComponent
            className="py-24"
            onReset={handleSubmit}
        />
    )
}