import classNames from "classnames";
import React from "react";

export default function ModelGrid({className, children}: {
    className?: string,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-3 md:gap-x-16 gap-y-11", className)}>
            {children}
        </div>
    )
}