import ChevronLeft from "@/svg/chevron-left-regular.svg?react";
import ChevronRight from "@/svg/chevron-right-regular.svg?react";
import classNames from "classnames";
import React, { SVGProps, useRef, useState } from "react";
import useBreakpoint from "../hooks/BreakpointHook";
import Carousel, { CarouselProps, CarouselRef } from "./Carousel";

function BannerButton({className, onClick, icon: Icon}: {
    className?: string,
    onClick?: () => void,
    icon: React.FC<SVGProps<SVGSVGElement>>
}) {
    return (
        <button type="button" onClick={onClick} className={classNames("w-10 rounded-[10px] bg-bg-600/40 hover:bg-bg-600/50 items-center justify-center fill-neutral-500", className)}>
            <Icon className="w-3" />
        </button>
    )
}

export default function BannerCarousel({className, autoplay, onItemClicked, children}: {
    className?: string,
    autoplay?: number,
    onItemClicked?: CarouselProps["onItemClicked"],
    children: React.ReactElement[],
}) {
    const ref = useRef<CarouselRef>(null);
    const lg = useBreakpoint("lg");
    const [mouseOver, setMouseOver] = useState(false);

    return (
        <div
            className={classNames("flex gap-4", className)}
            onPointerOver={() => setMouseOver(true)}
            onPointerOut={() => setMouseOver(false)}
        >
            <BannerButton className="hidden lg:flex z-10" onClick={() => ref.current?.previous()} icon={ChevronLeft} />
            <Carousel className="flex-1" ref={ref} gap={lg ? 72 : 12} infinite repetitions={3} autoplay={mouseOver ? undefined : autoplay} onItemClicked={onItemClicked}>
                {children}
            </Carousel>
            <BannerButton className="hidden lg:flex z-10" onClick={() => ref.current?.next()} icon={ChevronRight} />
        </div>
    )
}