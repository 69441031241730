import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";
import useAtLocation from "../hooks/AtLocationHook";

export interface TabProps extends ClickableProps {
    active?: boolean
}

export default function Tab({className, to, active: activeProp, ...props}: TabProps) {
    const atLocation = useAtLocation(to);
    const active = activeProp ?? atLocation;

    return (
        <Clickable to={to} className={classNames(`px-3 pb-2.5 body-l-md border-b-3 ${active ? "border-primary-300 text-text-800" : "border-transparent text-text-500"}`, className)} {...props} />
    )
}