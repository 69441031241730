import CircleCheckIcon from "@/svg/circle-check-solid.svg?react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

function USP({children}: {
    children: React.ReactNode
}) {
    return (
        <div className="flex items-center gap-2 body-m-md fill-white">
            <CircleCheckIcon className="w-4.5" />
            {children}
        </div>
    )
}

export default function USPs({className}: {
    className?: string
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-3.5", className)}>
            <USP>{t("signUp.usp.noHiddenCosts")}</USP>
            <USP>{t("signUp.usp.support")}</USP>
            <USP>{t("signUp.usp.cancelAnytime")}</USP>
            <USP>{t("signUp.usp.newContent")}</USP>
            <USP>{t("signUp.usp.discretion")}</USP>
            <USP>{t("signUp.usp.responsive")}</USP>
        </div>
    )
}