import CircleUserIcon from "@/svg/circle-user-solid.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import classNames from "classnames";
import React, { useState } from "react";

export default function UserDropdown({className, children}: {
    className?: string,
    children: React.ReactNode
}) {
    const [open, setOpen] = useState(false);
    const ref = useOnClickOutside<HTMLDivElement>(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <div ref={ref} className={classNames("relative", className)}>
            <button type="button" onClick={() => setOpen(!open)} className={`flex justify-center items-center size-9 rounded-lg ${open ? "bg-primary-700/50" : "bg-neutral-900/20 hover:bg-neutral-900/35"}`}>
                <CircleUserIcon className="w-5 fill-white" />
            </button>
            {open && (
                <div className="absolute top-[calc(100%+10px)] -right-1 z-20">
                    {children}
                </div>
            )}
        </div>
    )
}