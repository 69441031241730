import React from "react";
import ImageSetList from "../../components/ImageSetList";
import usePaginatedImageSets from "../../hooks/PaginatedImageSetsHook";

export default function FavoriteImageSets() {
    const {
        imageSets,
        loading,
        ...rest
    } = usePaginatedImageSets({
        filter: "favorite"
    });

    return (
        <ImageSetList
            items={loading ? undefined : imageSets}
            {...rest}
        />
    )
}