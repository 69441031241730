import React from "react";
import { useTranslation } from "react-i18next";
import VideoList, { VideoListProps } from "../VideoList";

export default function AllVideos(props: VideoListProps) {
    const {t} = useTranslation();

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-12">
                <h1 className="heading-2xl">{t("allVideos")}</h1>
                <VideoList {...props} />
            </div>
        </div>
    )
}