import { ListProps } from "@/js/types";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageSetCard, { ImageSetCardProps } from "./ImageSetCard";
import ImageSetCardSkeleton from "./ImageSetCardSkeleton";
import ImageSetGrid from "./ImageSetGrid";
import PaginatedList from "./PaginatedList";

export type SortBy = "newest" | "best_rated";
export interface ImageSetListProps extends ListProps<SortBy, Omit<ImageSetCardProps, "onUpdate">> {
    onUpdate: (id: number, data: {favorite?: boolean}) => void
}

export default function 
ImageSetList({
    itemsPerPage,
    page,
    totalItems,
    items,
    onUpdate,
    ...props
}: ImageSetListProps) {
    const {t} = useTranslation();
    const totalPages = totalItems !== undefined ? Math.ceil(totalItems / itemsPerPage) : undefined;

    return ( 
        <PaginatedList totalPages={totalPages} page={page} sortByChoices={[{
            value: "newest",
            label: t("sortBy.newest")
        }, {
            value: "best_rated",
            label: t("sortBy.bestRated")
        }]} {...props}>
            <ImageSetGrid>
                {items?.map(item => (
                    <ImageSetCard key={item.id} onUpdate={data => onUpdate(item.id, data)} {...item} />
                )) ?? Array<void>(page === totalPages ? ((totalItems ?? 0) % itemsPerPage) || itemsPerPage : itemsPerPage).fill().map((_, index) => (
                    <ImageSetCardSkeleton key={index} />
                ))}
            </ImageSetGrid>
        </PaginatedList>
    )
}