import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useSearchParams } from "react-router";
import Tab from "../../components/Tab";

export default function SearchResults({children}: {
    children?: React.ReactNode
}) {
    const {t, i18n} = useTranslation();
    const lng = i18n.language
    const [searchParams] = useSearchParams();
    const q = searchParams.get("q");

    if (q === null) {
        return <Navigate to="/" replace />
    }

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-10">
                <h1 className="heading-2xl">{t("searchResults", {q})}</h1>
                <div className="flex gap-1.5">
                    <Tab to={`/${lng}/search/videos?q=${q}`}>{t("videos")}</Tab>
                    <Tab to={`/${lng}/search/images?q=${q}`}>{t("images")}</Tab>
                    <Tab to={`/${lng}/search/models?q=${q}`}>{t("models")}</Tab>
                </div>
                {children ?? <Outlet />}
            </div>
        </div>
    )
}