import { Category } from "@/js/resources";
import { requireNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import Link from "./Link";

export type FeaturedCategoryProps = Pick<Category, "id" | "logo">;

export default function FeaturedCategories({className, categories}: {
    className?: string,
    categories?: FeaturedCategoryProps[]
}) {
    const { i18n: { language: lng } } = useTranslation();

    return (
        <div className={classNames("relative grid gap-2 sm:gap-3 grid-cols-2 sm:grid-cols-4 lg:grid-cols-5", className)}>
            {categories?.slice(0, 5).map(({id, logo}, index) => (
                <Link key={id} className={index >= 4 ? "hidden lg:block" : undefined} to={`/${lng}/categories/${id}`}>
                    <img src={requireNotNull(logo, "relation 'logo' must be loaded").src} className="aspect-category max-w-none w-full object-contain rounded-lg border border-neutral-200 bg-gradient-to-b from-bg-400 to-bg-300 p-[10%]" />
                </Link>
            )) ?? Array<void>(5).fill().map((_, index) => (
                <div key={index} className={classNames("aspect-category w-full rounded-lg border border-neutral-200 bg-gradient-to-b from-bg-400 to-bg-300 p-[10%]", {
                    "hidden lg:block": index >= 4
                })} />
            ))}
            <div className="absolute inset-8 blur-[50px] bg-primary-100 -z-10" />
        </div>
    )
}