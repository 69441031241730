import { QuillDelta, quillDeltaToRichText } from "@/js/richText";
import classNames from "classnames";
import React, { useMemo } from "react";
import RichText from "../RichText";

export default function Static({className, text}: {
    className?: string,
    text: QuillDelta[]
}) {
    const richText = useMemo(() => quillDeltaToRichText(text), [text]);

    return (
        <div className={classNames("[&_h1]:heading-2xl [&_h1]:mb-14 [&_h2]:heading-m [&_h2]:mb-5 [&_p]:body-l [&_p]:mb-10", className)}>
            <RichText>{richText}</RichText>
        </div>
    )
}