import { useUser } from "@/js/providers/UserProvider";
import { useModels } from "@/js/resources";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { ModelOverviewProps } from "../components/ModelOverview";
import ModelComponent from "../components/pages/Model";

export default function Model() {
    const {user} = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const {i18n} = useTranslation();
    const id = Number(useParams().id);

    const [model, {update}] = useModels({id, params: useMemo(() => ({
        locale: i18n.language,
        with: ["rating", "content", "featured_preview", "previews"]
    }), [i18n.language])});
    const [more, {loading}] = useModels({params: useMemo(() => ({
        locale: i18n.language,
        filter: "random",
        not: id,
        limit: 4
    }), [i18n.language, id])});

    const handleUpdate: ModelOverviewProps["onUpdate"] = data => {
        if (user === null) {
            const searchParams = new URLSearchParams();
            searchParams.set("redirect", location.pathname);
            navigate(`/login?${searchParams.toString()}`);
        }
        else {
            return update(data, "immediate");
        }
    }

    return <>
        {model && (
            <Helmet>
                <title>{model.first_name} ({model.age.toString()}) {model.description} - {import.meta.env.VITE_APP_NAME}</title>
            </Helmet>
        )}
        <ModelComponent
            model={model ?? undefined}
            more={loading ? undefined : more}
            onUpdate={handleUpdate}
        />
    </>
}