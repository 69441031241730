import CircleCheckIcon from "@/svg/circle-check-duotone-regular.svg?react";
import CircleExclamationIcon from "@/svg/circle-exclamation-duotone-regular.svg?react";
import TriangleExclamationIcon from "@/svg/triangle-exclamation-duotone-regular.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSSTransition, Transition, TransitionGroup } from "react-transition-group";
import { TransitionProps } from "react-transition-group/Transition";
import useClientSize from "../hooks/ClientSizeHook";

export interface ToastOptions {
    title: React.ReactNode,
    text: React.ReactNode,
    variant: "success" | "warning" | "error",
    duration: number
}

interface ToastProps extends ToastOptions, Omit<TransitionProps, "timeout" | "nodeRef"> {
    onDone: () => void
}

interface ToastState extends ToastOptions {
    id: number
}

const [Provider, useToaster] = createRequiredContext<(options: ToastOptions) => void>("ToasterProvider must be present in component tree");

function Toast({title, text, variant, duration, onDone, ...props}: ToastProps) {
    const innerRef = useRef<HTMLDivElement>(null);
    const [ref, {height}] = useClientSize<HTMLDivElement>();
    const [show, setShow] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => setShow(false), duration);
        return () => clearTimeout(timeout);
    }, [setShow, duration]);

    return (
        <Transition {...props} timeout={500} nodeRef={ref}>
            {state => (
                <div ref={ref} className="transition-[height,padding-top] duration-500" style={state === "exiting" ? {
                    height: "0",
                    paddingTop: "0"
                } : {
                    height,
                    paddingTop: "12px"
                }}>
                    <CSSTransition                  
                        nodeRef={innerRef}
                        classNames={{
                            appear: "opacity-0",
                            appearActive: "!opacity-100 transition-opacity duration-700",
                            appearDone: "opacity-100",
                            exit: "opacity-100",
                            exitActive: "!opacity-0 transition-opacity duration-700",
                            exitDone: "opacity-0"
                        }}
                        in={show}
                        timeout={700}
                        onExited={onDone}
                        unmountOnExit
                        appear
                    >
                        <div ref={innerRef} className="px-3.5 py-2 shadow-toast rounded-xl border border-bg-800 bg-bg-500 flex items-center gap-4 w-80">
                            {variant === "success" ? (
                                <CircleCheckIcon className="w-8 fill-success-500" />
                            ) : variant === "warning" ? (
                                <CircleExclamationIcon className="w-8 fill-warning-500" />
                            ) : (
                                <TriangleExclamationIcon className="w-8 fill-error-700" />
                            )}
                            <div className="flex-1 flex flex-col gap-0.5">
                                <span className="body-l-md">{title}</span>
                                <span className="body-m text-text-700">{text}</span>
                            </div>
                        </div>
                    </CSSTransition>
                </div>
            )}
        </Transition>
    )
}

export { useToaster };
export default function ToasterProvider({children}: {
    children: React.ReactNode
}) {
    const [toasts, setToasts] = useState<ToastState[]>([]);
    const toast = useCallback((options: ToastOptions) => {
        setToasts(toasts => [...toasts, {
            id: Math.max(0, ...toasts.map(({id}) => id)) + 1,
            ...options
        }]);
    }, [setToasts]);

    return <>
        <Provider value={toast}>
            {children}
        </Provider>
        <TransitionGroup className="fixed z-50 left-1/2 -translate-x-1/2 bottom-16 flex flex-col-reverse">
            {toasts.map(({id, ...props}) => (
                <Toast
                    key={id}
                    onDone={() => setToasts(toasts => toasts.filter(toast => toast.id !== id))}
                    {...props}
                />
            ))}
        </TransitionGroup>
    </>
}