import logo from "@/media/logo.png";
import EighteenPlusIcon from "@/svg/18-plus-svgrepo-com.svg?react";
import React from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import Button from "./form/Button";

export default function YouthProtectionPopup({onConfirm, footer}: {
    onConfirm: () => void | Promise<void>,
    footer?: React.ReactNode
}) {
    const {t} = useTranslation();

    return createPortal((
        <div className="fixed inset-0 flex flex-col overflow-auto z-50">
            <div className="grow flex justify-center items-center flex-col gap-11 sm:gap-14 bg-bg-500/85 backdrop-blur-md px-4 pt-14 pb-36">
                <img className="w-auto h-12 sm:h-14" src={logo} />
                <div className="bg-bg-100 rounded-xl p-5 sm:p-14 flex flex-col gap-6 sm:gap-9 items-center max-w-[900px] w-full">
                    <h1 className="flex flex-col sm:flex-row items-center gap-4 heading-2xl text-center">
                        <EighteenPlusIcon className="size-12 fill-primary-800"/>
                        {t("youthProtection.title")}
                    </h1>
                    <p className="body-l text-center whitespace-pre-wrap">{t("youthProtection.text")}</p>
                    <div className="flex flex-col sm:flex-row gap-5 max-w-64 w-full sm:w-auto sm:max-w-none">
                        <Button variant="secondary" linkType="new-tab" to={import.meta.env.VITE_YOUTH_PROTECTION_DENY_URL}>{t("youthProtection.deny")}</Button>
                        <Button variant="primary" onClick={onConfirm}>{t("youthProtection.confirm")}</Button>
                    </div>
                </div>
            </div>
            {footer}
        </div>
    ), document.body);
}