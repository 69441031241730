import React from "react";
import { useTranslation } from "react-i18next";
import { useDayjs } from "../../providers/DayjsProvider";
import Info from "./Info";

export default function ReleaseDate({className, date}: {
    className?: string,
    date: Date
}) {
    const {t} = useTranslation();
    const dayjs = useDayjs();

    return (
        <Info className={className}>
            {t("releaseDate", {date: dayjs(date).format("L")})}
        </Info>
    )
}