import { isNotNull } from "@enymo/ts-nullsafe";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router";
import { useUser } from "../providers/UserProvider";
import { Locale, locales } from "../types";
import LayoutComponent from "./components/Layout";

export default function Layout({children, background, noYouthProtection = false, localePath = false, locale}: {
    children?: React.ReactNode,
    background: "default" | "account",
    noYouthProtection?: boolean,
    localePath?: boolean,
    locale?: Locale
}) {
    const {i18n} = useTranslation();
    const {user, logout} = useUser();

    useEffect(() => {
        if (locale !== undefined && locale !== i18n.language && locales.includes(locale)) {
            i18n.changeLanguage(locale);
        }
    }, [locale, i18n])

    return (
        <LayoutComponent
            background={background}
            user={user?.role === "admin" ? "admin" : (isNotNull(user?.plan) || user?.role === "permanentAccess") ? "member" : user !== null ? "login" : "logout"}
            onLogout={logout}
            noYouthProtection={noYouthProtection}
            localePath={localePath}
        >
            {children ?? <Outlet />}
        </LayoutComponent>
    )
}