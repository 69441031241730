import { useUser } from "@/js/providers/UserProvider";
import React from "react";
import LoginComponent, { Submit } from "../components/pages/Login";

export default function Login() {
    const {login} = useUser();

    const handleSubmit = async ({email, password, remember}: Submit) => {
        await login(email, password, remember);
    }

    return (
        <LoginComponent
            className="px-4 py-20"
            onLogin={handleSubmit}
        />
    )
}
