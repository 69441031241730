import { useUser } from "@/js/providers/UserProvider";
import { useVideos } from "@/js/resources";
import { Resolution } from "@/js/types";
import { isNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { route } from "ziggy-js";
import VideoComponent from "../components/pages/Video";
import { VideoOverviewProps } from "../components/VideoOverview";
import { useToaster } from "../providers/ToasterProvider";

export default function Video() {
    const {t, i18n} = useTranslation();
    const {user} = useUser();
    const location = useLocation();
    const id = Number(useParams().id);
    const navigate = useNavigate();
    const toast = useToaster();

    const [requestedResolution, setRequestedResolution] = useState<Resolution>(() => sessionStorage.getItem("resolution") as Resolution ?? "fhd");
    const [playing, setPlaying] = useState(false);

    const [video, {update}] = useVideos({id, params: useMemo(() => ({
        locale: i18n.language,
        with: ["featured_preview", "rating", "previews", "models", "resolutions", "highest_resolution", "category", "downloadable"]
    }), [i18n.language])});

    const modelIds = useMemo(() => video?.models?.map(({id}) => id), [video]);

    const [moreOfModel, {loading: moreOfModelLoading}] = useVideos({autoRefresh: isNotNull(modelIds), params: useMemo(() => ({
        locale: i18n.language,
        models: modelIds,
        filter: "random",
        not: id,
        limit: 3,
        with: ["featured_preview", "featured_model"]
    }), [i18n.language, id, modelIds?.join()])});
    const [recommendations, {loading: recommendationsLoading}] = useVideos({params: useMemo(() => ({
        locale: i18n.language,
        filter: "random",
        not: id,
        limit: 8,
        with: ["featured_preview", "featured_model"]
    }), [i18n.language, id])})

    const handlePlay = () => {
        if (isNotNull(user?.plan) || user?.role === "admin" || user?.role === "permanentAccess") {
            setPlaying(true);
            if (!video?.view) {
                update({view: true}, "immediate");
            }
        }
        else {
            navigate("/account/select-plan");
        }
    }

    const handleUpdate: VideoOverviewProps["onUpdate"] = data => {
        if (user === null) {
            const searchParams = new URLSearchParams;
            searchParams.set("redirect", location.pathname);
            navigate(`/login?${searchParams.toString()}`);
        }
        else {
            return update(data, "immediate");
        }
    }

    const handleDownload = async () => {
        if (!video?.downloadable) {
            toast({
                duration: 3000,
                variant: "error",
                title: t("download.disallowed"),
                text: !isNotNull(user?.plan) ? (
                    t("download.disallowed.noMember")
                ) : !user.plan.features.includes("downloads") ? (
                    t("download.disallowed.wrongPlan")
                ) : t("download.disallowed.overLimit")
            });
        }
        else {
            toast({
                duration: 3000,
                variant: "success",
                title: t("download.starting"),
                text: t("download.starting.text")
            });
            window.location.href = route("videos.download", {video: id});
        }
    }

    return <>
        {video && (
            <Helmet>
                <title>{video.title} - {import.meta.env.VITE_APP_NAME}</title>
            </Helmet>
        )}
        <VideoComponent
            isMember={isNotNull(user?.plan) || user?.role === "admin" || user?.role === "permanentAccess"}
            video={video ?? undefined}
            requestedResolution={requestedResolution}
            onChangeResolution={setRequestedResolution}
            onPlay={handlePlay}
            onUpdate={handleUpdate}
            onDownload={handleDownload}
            playing={playing}
            moreOfModel={!video || moreOfModelLoading ? undefined : moreOfModel}
            recommendations={recommendationsLoading ? undefined : recommendations}
        />
    </>
}