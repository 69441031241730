import classNames from "classnames";
import React, { useEffect, useRef } from "react";

export default function Foldable({
    className,
    open = false,
    scroll = false,
    delay = 150,
    children
}: {
    className?: string,
    open?: boolean,
    scroll?: boolean,
    delay?: number,
    children: React.ReactNode
}) {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const target = ref.current;
        if (target) {
            if (open) {
                target.style.height = "auto";
                const height = `${target.clientHeight}px`;
                target.style.height = "0";
                const timeouts = [
                    setTimeout(() => {
                        target.style.height = height;
                    }),
                    setTimeout(() => {
                        target.style.height = "auto"
                    }, delay)
                ];
                return () => timeouts.forEach(clearTimeout);
            }
            else {
                target.style.height = `${target.clientHeight}px`;
                const timeout = setTimeout(() => {
                    target.style.height = "0";
                })
                return () => clearTimeout(timeout);
            }
        }
    }, [ref, open]);
    
    return (
        <div ref={ref} className={classNames(`transition-[height,padding] h-0 ${scroll ? "overflow-auto" : "overflow-hidden"}` , className)} style={!open ? {
            paddingTop: "0",
            paddingBottom: "0"
        } : undefined}>
            {children}
        </div>
    )
}