import { CheckboxList } from "@enymo/glissade";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ContextError from "../ContextError";
import Button from "../form/Button";
import CheckboxInput from "../form/CheckboxInput";
import EMailInput from "../form/EmailInput";
import Input from "../form/Input";
import RadioGroup from "../form/RadioGroup";
import RadioInput from "../form/RadioInput";

export interface Submit {
    name: string,
    email: string,
    urls: string[],
    type: "abuse" | "copyright",
    depicted: boolean,
    issues: ('non_consentual' | 'trafficking' | 'personal_info' | 'weapons' | 'minors' | 'drugs')[],
    description: string,
    signature: string
}

export default function Report({className, urls, onSubmit}: {
    className?: string,
    urls?: string[],
    onSubmit: SubmitHandler<Submit>
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>({
        defaultValues: {
            depicted: true,
            type: "abuse"
        },
        values: urls !== undefined ? {
            name: "",
            email: "",
            type: "abuse",
            depicted: true,
            issues: [],
            urls: urls,
            description: "",
            signature: ""
        } : undefined
    });

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await onSubmit(data);
        form.reset();
    }
    
    return (
        <div className={classNames("flex flex-col gap-14", className)}>
            <h1 className="heading-2xl">{t("report.title")}</h1>
            <p className="body-l whitespace-pre-wrap">{t("report.text")}</p>
            <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-9 max-w-2xl">
                <div className="flex flex-col sm:flex-row gap-9 sm:gap-3">
                    <Input className="sm:flex-1" name="name" label={t("report.name")} options={{
                        required: t("report.name.required")
                    }} />
                    <EMailInput className="sm:flex-1" options={{
                        required: t("input.email.required")
                    }} />
                </div>
                <div className="flex flex-col gap-3">
                    <label className="body-m-md">{t("report.urls")}</label>
                    {Array<void>(5).fill().map((_, index) => (
                        <Input key={index} name={`urls.${index}`} placeholder="https://" options={{
                            required: index === 0 ? t("report.urls.required") : false,
                            pattern: {
                                value: /https?:\/\/www\.sextermedia\.com\/[a-zA-Z0-9-_/]*/,
                                message: t("report.urls.pattern")
                            }
                        }} />
                    ))}
                </div>
                <RadioGroup name="type" label={t("report.type")}>
                    <div className="flex flex-col sm:flex-row items-center gap-10">
                        <RadioInput value="abuse">{t("report.type.abuse")}</RadioInput>
                        <RadioInput value="copyright">{t("report.type.copyright")}</RadioInput>
                    </div>
                </RadioGroup>
                <RadioGroup name="depicted" label={t("report.depicted")}>
                    <div className="flex items-center gap-10">
                        <RadioInput value={true}>{t("yes")}</RadioInput>
                        <RadioInput value={false}>{t("no")}</RadioInput>
                    </div>
                </RadioGroup>
                <CheckboxList name="issues" options={{
                    required: t("report.issues.required")
                }}>
                    <div className="flex flex-col gap-3">
                        <label className="body-m-md">{t("report.issues")}</label>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-3">
                            <CheckboxInput value="non_consentual">{t("report.issues.nonConsentual")}</CheckboxInput>
                            <CheckboxInput value="trafficking">{t("report.issues.trafficking")}</CheckboxInput>
                            <CheckboxInput value="personal_info">{t("report.issues.personalInfo")}</CheckboxInput>
                            <CheckboxInput value="weapons">{t("report.issues.weapons")}</CheckboxInput>
                            <CheckboxInput value="minors">{t("report.issues.minors")}</CheckboxInput>
                            <CheckboxInput value="drugs">{t("report.issues.drugs")}</CheckboxInput>
                        </div>
                        <ContextError />
                    </div>
                </CheckboxList>
                <div className="flex flex-col gap-5">
                    <Input name="description" type="textarea" rows={10} label={t("report.description")} options={{
                        required: t("report.description.required")
                    }} />
                    <p className="body-s">{t("report.description.info")}</p>
                </div>
                <div className="flex gap-3">
                    <div className="flex-1 flex flex-col gap-5">
                        <Input name="signature" label={t("report.signature")} options={{
                            required: t("report.signature.required")
                        }} />
                        <p className="body-s">{t("report.signature.info")}</p>
                    </div>
                    <div className="hidden sm:block flex-1" />
                </div>
                <p className="body-m">{t("report.perjury")}</p>
                <Button className="self-end mt-1" variant="primary" submit>{t("report.submit")}</Button>
            </Form>
        </div>
    )
}