import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import useClientSize from "../hooks/ClientSizeHook";
import Select from "./form/Select";
import Pagination from "./Pagination";

export default function PaginatedList<T extends string>({
    className,
    sortBy,
    onChangeSortBy,
    sortByChoices,
    totalPages,
    page,
    onChangePage,
    children
}: {
    className?: string,
    sortBy: T,
    onChangeSortBy: (sortBy: T) => void,
    sortByChoices: {
        value: T,
        label: string
    }[],
    totalPages?: number,
    page: number,
    onChangePage: (page: number) => void,
    children: React.ReactNode
}) {
    const {t} = useTranslation();
    const [ref, {width}] = useClientSize<HTMLDivElement>();

    return (
        <div ref={ref} className={classNames("flex flex-col gap-9", className)}>
            <div className="flex items-center flex-wrap gap-10 justify-between">
                <div className="flex items-center gap-4 body-m">
                    {t("sort")}:
                    <Select className="min-w-32" value={sortBy} onChange={value => onChangeSortBy(value as T)} choices={sortByChoices} />
                </div>
                {width !== undefined && totalPages !== undefined && <Pagination page={page} totalPages={totalPages} onChangePage={onChangePage} />}
            </div>
            {children}
            <div className="flex justify-end">
                {width !== undefined && totalPages !== undefined && <Pagination page={page} totalPages={totalPages} onChangePage={onChangePage} />}
            </div>
        </div>
    )
}