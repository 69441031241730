import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import React, { useState } from "react";

export default function Footer({
    admin = false,
    copyright,
    items
}: {
    admin?: boolean,
    copyright?: React.ReactNode,
    items: {
        onClick?: () => void,
        to?: string,
        linkType?: ClickableProps["linkType"],
        children: React.ReactNode
    }[]
}) {
    const [testSentry, setTestSentry] = useState(false);

    if (testSentry) {
        throw new Error("This is a test event");
    }

    return (
        <div className="px-8 py-4 flex justify-center flex-col-reverse xl:flex-row xl:justify-between gap-3 items-center text-sm text-text-600 bg-bg-300">
            {admin ? <button onClick={() => setTestSentry(true)}>{copyright}</button> : copyright}
            <div className="self-stretch md:self-center flex flex-col md:flex-row md:items-center gap-2.5 md:gap-5">
                {items.map((props, index) => (
                    <Clickable key={index} className="cursor-pointer hover:text-text-700" {...props} />
                ))}
            </div>
        </div>
    )
}