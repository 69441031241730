import { useUser } from "@/js/providers/UserProvider";
import React from "react";
import PasswordComponent, { Submit } from "../../components/pages/account/Password";

export default function Password() {
    const {update} = useUser();

    const handleSubmit = async (data: Submit) => {
        await update({
            current_password: data.current_password,
            password: data.new_password
        });
    }

    return (
        <PasswordComponent className="max-w-sm" onSubmit={handleSubmit} />
    )
}