import { useModels } from "@/js/resources";
import { Params } from "@enymo/react-resource-hook";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { useSanitizedNumber, useSanitizedString } from "../../admin/hooks/SanitizedParamHook";
import { SortBy } from "../components/ModelList";
import useBreakpoint from "./BreakpointHook";

export default function usePaginatedModels(params: Params = {}, dependencies: React.DependencyList = []) {
    const {i18n} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = useSanitizedNumber(searchParams.get("page"), {min: 1}, 1);
    const sortBy = useSanitizedString(searchParams.get("sort-by"), ["newest", "alphabetic", "best_rated"], "newest");

    const xl = useBreakpoint("xl");
    const sm = useBreakpoint("sm");
    const itemsPerPage = xl ? 30 : sm ? 20 : 10

    const [models, {loading, meta}] = useModels({params: useMemo(() => ({
        locale: i18n.language,
        per_page: itemsPerPage,
        page,
        sort_by: (() => {
            switch (sortBy) {
                case "newest":
                    return {name: "created_at", direction: "desc"};
                case "alphabetic":
                    return {name: "first_name", direction: "asc"};
                case "best_rated":
                    return {name: "likes", direction: "desc"}
            }
        })(),
        ...params
    }), [i18n.language, page, itemsPerPage, sortBy, ...dependencies])});

    if (meta?.last_page && meta.last_page < page) {
        searchParams.set("page", meta.last_page.toString());
        setSearchParams(searchParams);
    }

    const onChangeSortBy = (sortBy: SortBy) => {
        searchParams.set("sort-by", sortBy);
        setSearchParams(searchParams);
    }

    const onChangePage = (page: number) => {
        searchParams.set("page", page.toString());
        setSearchParams(searchParams);
    }

    return {models, loading, totalItems: meta?.total, itemsPerPage, sortBy, page, onChangePage, onChangeSortBy}
}