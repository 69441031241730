import classNames from "classnames";
import React, { CSSProperties } from "react";

export default function OverviewInfo({className, title, style, children}: {
    className?: string,
    style?: CSSProperties,
    title: React.ReactNode,
    children: React.ReactNode
}) {    
    return (
        <div className={classNames("flex gap-2.5 items-start body-m", className)} style={style}>
            <span className="body-m-md text-text-600">{title}:</span>
            {children}
        </div>
    )
}