import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, useSearchParams } from "react-router";
import { useUser } from "../providers/UserProvider";

export default function Gate({realm}: {
    realm: "login" | "verify-email" | "app"
}) {
    const {i18n: {language: lng}} = useTranslation();
    const [searchParams] = useSearchParams();
    const {user, loading} = useUser();

    if (loading) {
        return null;
    }
    else if (realm === "login" && user !== null) {
        console.log("gate nav", window.location.href);
        return <Navigate to={user.email_verified_at === null ? "/verify-email" : (searchParams.get("redirect") ?? `/${lng}`)} replace />
    }
    else if (realm === "app" && (user === null || user.email_verified_at === null)) {
        const searchParams = new URLSearchParams();
        searchParams.set("redirect", window.location.pathname);
        return <Navigate to={`${user === null ? "/sign-up" : "/verify-email"}?${searchParams.toString()}`} replace />
    }
    else if (realm === "verify-email" && user?.email_verified_at !== null) {
        return <Navigate to={user === null ? "/login" : `/${lng}`} replace />
    }
    else {
        return <Outlet />
    }
}