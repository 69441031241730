import ChevronLeft from "@/svg/chevron-left-regular.svg?react";
import ChevronRight from "@/svg/chevron-right-regular.svg?react";
import classNames from "classnames";
import React from "react";
import useBreakpoint from "../hooks/BreakpointHook";

function PaginationButton({onClick, icon: Icon, disabled}: {
    onClick: () => void,
    icon: React.FC<React.SVGProps<SVGSVGElement>>,
    disabled: boolean
}) {
    return (
        <button onClick={onClick} disabled={disabled} className="w-11 h-10 flex justify-center items-center rounded-lg bg-primary-500 hover:bg-primary-600 fill-white disabled:bg-neutral-100/40 disabled:fill-neutral-400">
            <Icon className="w-2.5" />
        </button>
    )
}

function PaginationPage({onClick, page, selected}: {
    onClick: () => void,
    page: number,
    selected: boolean
}) {
    return (
        <button className={classNames(
            "rounded-lg border-2 bg-[#ECEBF126] font-medium text-lg flex-1 min-w-6 sm:min-w-11 self-stretch",
            selected ? "border-primary-600 text-primary-600" : "border-transparent text-text-600"
        )} onClick={onClick}>
            {page}
        </button>
    )
}

export default function Pagination({className, page, totalPages, onChangePage}: {
    className?: string,
    page: number,
    totalPages: number,
    onChangePage: (page: number) => void
}) {
    const sm = useBreakpoint("sm");
    const showPages = Math.min(sm ? 5 : 3, totalPages - 2);
    const offset = Math.max(sm ? 2 : 1, Math.min(page - (sm ? 2 : 1), totalPages - (sm ? 5 : 3)));

    return (
        <div className={classNames("flex gap-2 sm:gap-5", className)}>
            <PaginationButton disabled={page <= 1} onClick={() => onChangePage(page - 1)} icon={ChevronLeft} />
            <div className="flex gap-1.5 sm:gap-2 grow body-m text-text-500 items-center">
                <PaginationPage page={1} selected={page === 1} onClick={() => onChangePage(1)} />
                {offset > 2 && "..."}
                {showPages > 0 && Array<void>(showPages).fill().map((_, index) => (
                    <PaginationPage page={index + offset} selected={page === index + offset} onClick={() => onChangePage(index + offset)} />
                ))}
                {offset < totalPages - (sm ? 5 : 3) && "..."}
                {totalPages > 1 && <PaginationPage page={totalPages} selected={page === totalPages} onClick={() => onChangePage(totalPages)} />}
            </div>
            <PaginationButton disabled={page >= totalPages} onClick={() => onChangePage(page + 1)} icon={ChevronRight} />
        </div>
    )
}