import { useUser } from "@/js/providers/UserProvider";
import { usePlans } from "@/js/resources";
import React, { useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router";
import SignUpComponent, { Submit } from "../components/pages/SignUp";

export default function SignUp() {
    const {signUp} = useUser();
    const navigate = useNavigate();
    const [plans] = usePlans();
    const offers = useMemo(() => {
        const offers = [];
        const yearly = plans.find(({period}) => period === "yearly");
        if (yearly !== undefined) {
            offers.push(plans.reduce((prev, cur) => cur.period === "yearly" && cur.price < prev.price ? cur : prev, yearly));
        }
        const monthly = plans.find(({period}) => period === "monthly");
        if (monthly !== undefined) {
            offers.push(plans.reduce((prev, cur) => cur.period === "monthly" && cur.price < prev.price ? cur : prev, monthly));
        }
        return offers.map(({trial_days, trial_price, features, ...props}) => ({
            trial_days: trial_days ?? undefined,
            trial_price: trial_price ?? undefined,
            ...props
        }));
    }, [plans]);
    const [searchParams] = useSearchParams();

    const handleSubmit = async ({email, password}: Submit) => {
        await signUp(email, password);
        navigate("/verify-email");
    }

    return (
        <SignUpComponent
            className="px-4 py-20"
            offers={offers}
            onSignUp={handleSubmit}
            redirect={searchParams.get("redirect") ?? undefined}
        />
    )
}