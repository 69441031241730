import { useEffect, useState } from "react";

const getDimensions = () => ({
    width: window.innerWidth,
    height: window.innerHeight
});

export default function useScreenSize() {
    const [{width, height}, setDimensions] = useState(getDimensions);

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [setDimensions]);

    return {width, height};
}