import { SubmitHandler } from "@enymo/react-form-component";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { route } from "ziggy-js";
import ForgotPasswordComponent, { Submit } from "../components/pages/ForgotPassword";
import ForgotPasswordSubmitted from "../components/pages/ForgotPasswordSubmitted";

export default function ForgotPassword() {
    const {i18n} = useTranslation();
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit: SubmitHandler<Submit> = async ({email}) => {
        await axios.post(route("users.forgot-password", {locale: i18n.language}), {email});
        setSubmitted(true);
    }

    return submitted ? (
        <ForgotPasswordSubmitted className="py-24" onBack={() => setSubmitted(false)} />
    ) : (
        <ForgotPasswordComponent className="py-24" onSubmitEmail={handleSubmit} />
    )
}