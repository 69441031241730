import { Category } from "@/js/resources";
import ImagesIcon from "@/svg/images-solid.svg?react";
import VideoIcon from "@/svg/video-solid.svg?react";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";

export interface CategoryCardProps extends Category {
    className?: string
}

function CategoryCardLink({to, children}: {
    to: string,
    children: React.ReactNode
}) {
    return (
        <Link className="rounded-lg bg-[#ECEBF126] hover:bg-[#ECEBF11A] hover:text-text-900 hover:fill-white h-8.5 flex flex-1 gap-2 justify-center items-center body-l-md fill-neutral-900" to={to}>
            {children}
        </Link>
    )
}

export default function CategoryCard({
    className,
    id,
    title,
    logo,
    preview,
    description
}: CategoryCardProps) {
    assertNotNull(logo, "relation 'logo' must be loaded");
    assertNotNull(preview, "relation 'preview' must be loaded");

    const {t, i18n: { language: lng }} = useTranslation();

    return (
        <div className={classNames("rounded-lg bg-gradient-to-l from-bg-300/80 to-bg-900/80 p-4 sm:p-7 flex flex-col sm:flex-row justify-between sm:items-start gap-8", className)}>
            <div className="max-w-[720px] shrink flex flex-col items-start gap-4">
                <img className="max-h-16 h-full w-auto" src={logo.src} width={logo.width} height={logo.height} />
                <h2 className="heading-m">{title}</h2>
                <p className="body-m">{description}</p>
            </div>
            <div className="flex flex-col gap-2.5">
                <img className="rounded-lg aspect-card w-auto sm:w-72 object-cover skeleton" src={preview.src} width={preview.width} height={preview.height} />
                <div className="flex gap-1.5">
                    <CategoryCardLink to={`/${lng}/categories/${id}/videos`}>
                        <VideoIcon className="w-6" />
                        {t("category.videos")}
                    </CategoryCardLink>
                    <CategoryCardLink to={`/${lng}/categories/${id}/images`}>
                        <ImagesIcon className="w-5" />
                        {t("category.images")}
                    </CategoryCardLink>
                </div>
            </div>
        </div>
    )
}