import logo from "@/media/logo.png";
import BarsIcon from "@/svg/bars-regular.svg?react";
import ChevronLeft from "@/svg/chevron-left-regular.svg?react";
import MagnifyingGlassIcon from "@/svg/magnifying-glass-regular.svg?react";
import XMarkIcon from "@/svg/xmark-regular.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import { Clickable } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React, { useState } from "react";
import { Link, useLocation } from "react-router";
import Foldable from "./Foldable";
import Search from "./form/Search";

function Logo({className}: {
    className?: string
}) {
    return (
        <Link to="/" className={className}>
            <img src={logo} alt="Sextermedia" className="h-9 w-auto" width={259} height={65} />
        </Link>
    )
}

export default function Header({
    onChangeSearch,
    searchPlaceholder,
    items,
    primaryActions
}: {
    onChangeSearch?: (search: string | null) => void,
    searchPlaceholder?: string,
    items: {
        children: React.ReactNode,
        to?: string,
        active?: boolean,
        onClick?: () => void
    }[],
    primaryActions?: React.ReactNode
}) {
    const location = useLocation();

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState(false);

    const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false), [setOpen]);

    const handleCloseHeader = (then?: () => void) => () => {
        setOpen(false);
        then?.();
    }

    return (
        <div ref={ref} className="relative h-14 px-5 md:px-8 flex items-center justify-between gap-5">
            {search ? (
                <button type="button" onClick={() => setSearch(false)} className="w-5 flex justify-center">
                    <ChevronLeft className="fill-neutral-800 w-3" />
                </button>
            ) : <>
                <div className="flex items-center gap-5 xl:gap-6">
                    <button type="button" className="xl:hidden w-5 flex justify-center" onClick={() => setOpen(!open)}>
                        <XMarkIcon className={classNames("fill-neutral-800 w-4", {
                            "hidden": !open
                        })} />
                        <BarsIcon className={classNames("fill-white w-5", {
                            "hidden": open
                        })} />
                    </button>
                    <Logo className="hidden sm:block" />
                    <div className="hidden px-6 h-7 xl:flex border-neutral-900/15 border-l gap-6">
                        {items.map(({to, active, ...props}, index) => (
                            <Clickable key={index} to={to} className={`flex items-center body-l-md ${(active ?? (to && location.pathname.startsWith(to))) ? "text-primary-900" : "hover:text-white"}`} {...props} />
                        ))}
                    </div>
                </div>
                <div className="flex justify-center sm:hidden">
                   <Logo />
                </div>
            </>}
            <div className={classNames("flex justify-end items-center gap-5 md:gap-10 flex-1", {
                "flex-1": search
            })}>
                {!search && (
                    <button type="button" onClick={() => setSearch(true)} className="sm:hidden">
                        <MagnifyingGlassIcon className="fill-neutral-900 w-5" />
                    </button>
                )}
                {onChangeSearch && <Search className={`sm:max-w-80 flex-1 ${search ? "block" : "hidden"} sm:block`} placeholder={searchPlaceholder} onChange={onChangeSearch} />}
                {!search && primaryActions}
            </div>
            <Foldable className="xl:hidden absolute top-full left-0 w-full px-4 py-7 flex flex-col gap-2 bg-bg-300 z-20" open={open}>
                {items.map(({to, onClick, active, ...props}, index) => (
                    <Clickable key={index} to={to} onClick={handleCloseHeader(onClick)} className={classNames("flex items-center h-10 px-3 rounded-md bg-neutral-300/60 body-l-md", {
                        "text-primary-900": active ?? (to && location.pathname.startsWith(to))
                    })} {...props} />
                ))}
            </Foldable>
        </div>
    )
}