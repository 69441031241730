import CheckIcon from "@/svg/check-regular.svg?react";
import classNames from "classnames";
import React from "react";

export default function Checkbox({className, error, checked}: {
    className?: string,
    checked?: boolean,
    error?: boolean
}) {
    return (
        <div className={classNames(`flex justify-center items-center fill-white size-6 rounded-md group ${checked ? "bg-primary-500" : `border bg-bg-200 peer-focus:border-primary-500 peer-checked:bg-primary-500 peer-checked:border-0 ${error ? "border-error-500" : "border-neutral-200"}`}`, className)}>
            <CheckIcon className={classNames("w-3.5 peer-checked:group-[]:block", {
                "hidden": !checked
            })} />
        </div>
    )
}