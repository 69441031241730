import { useImageSets, useVideos } from "@/js/resources";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ComingSoonComponent from "../components/pages/ComingSoon";

export default function ComingSoon() {
    const {i18n} = useTranslation();

    const [videos, {loading: videosLoading}] = useVideos({params: useMemo(() => ({
        locale: i18n.language,
        with: ["featured_model", "featured_preview"],
        filter: "soon",
        sort_by: {name: "publish_at", direction: "asc"}
    }), [i18n.language])});
    const [images, {loading: imageSetsLoading}] = useImageSets({params: useMemo(() => ({
        locale: i18n.language,
        with: ["featured_model", "previews"],
        filter: "soon",
        sort_by: {name: "publish_at", direction: "asc"}
    }), [i18n.language])});

    return (
        <ComingSoonComponent
            videos={videosLoading ? undefined : videos}
            images={imageSetsLoading ? undefined : images}
        />
    )
}