import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Link from "../Link";

export default function VerifyEmail({className, onSendAgain}: {
    className?: string,
    onSendAgain: () => void
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("flex flex-col items-center", className)}>
            <h1 className="heading-2xl text-center">{t("verifyEmail.title")}</h1>
            <h2 className="mt-5 heading-m text-center">{t("verifyEmail.subtitle")}</h2>
            <span className="body-l-md text-center mt-9">
                <Trans i18nKey="verifyEmail.sendAgain">
                    Didn't get anything? <Link onClick={onSendAgain}>Send again</Link>
                </Trans>
            </span>
        </div>
    )
}