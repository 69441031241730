import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useSearchParams } from "react-router";
import ImageSetCard, { ImageSetCardProps } from "../ImageSetCard";
import ImageSetCardSkeleton from "../ImageSetCardSkeleton";
import ImageSetGrid from "../ImageSetGrid";
import Tab from "../Tab";
import VideoCard, { VideoCardProps } from "../VideoCard";
import VideoCardSkeleton from "../VideoCardSkeleton";
import VideoGrid from "../VideoGrid";

export default function ComingSoon({videos, images}: {
    videos?: Omit<VideoCardProps, "onUpdate">[],
    images?: Omit<ImageSetCardProps, "onUpdate">[]
}) {
    const {t, i18n: {language: lng}} = useTranslation();
    const [searchParams] = useSearchParams();
    const type = searchParams.get("type");

    if (type === null) {
        return <Navigate to={`/${lng}/coming-soon?type=videos`} replace />
    }

    return (
        <div className="flex justify-center px-4 py-10 sm:py-20">
            <div className="max-w-7xl w-full flex flex-col">
                <h1 className="heading-2xl">{t("comingSoon")}</h1>
                <div className="mt-10 flex gap-2.5">
                    <Tab to={`/${lng}/coming-soon?type=videos`}>{t("videos")}</Tab>
                    <Tab to={`/${lng}/coming-soon?type=images`}>{t("images")}</Tab>
                </div>
                {type === "videos" ? (
                    <VideoGrid className="mt-9">
                        {videos?.map(video => (
                            <VideoCard key={video.id} {...video} />
                        )) ?? Array<void>(8).fill().map((_, index) => (
                            <VideoCardSkeleton key={index} />
                        ))}
                    </VideoGrid>
                ) : (
                    <ImageSetGrid className="mt-9">
                        {images?.map(image => (
                            <ImageSetCard key={image.id} {...image} />
                        )) ?? Array<void>(6).fill().map((_, index) => (
                            <ImageSetCardSkeleton key={index} />
                        ))}
                    </ImageSetGrid>
                )}
            </div>
        </div>
    )
}