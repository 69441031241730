import classNames from "classnames";
import React from "react";
import Repeat from "./Repeat";

function Line({className}: {
    className?: string
}) {
    return <div className={classNames("h-4.5 rounded-sm skeleton", className)} />
}

export default function VideoOverviewSkeleton({className}: {
    className?: string
}) {
    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="self-start max-w-[640px] w-full h-8 rounded-sm skeleton" />
            <div className="aspect-video rounded-xl skeleton mt-8" />
            <div className="flex justify-between mt-7">
                <div className="w-[90px] sm:w-[480px] h-6 skeleton rounded-sm" />
                <div className="w-[220px] h-6 skeleton rounded-sm" />
            </div>
            <div className="flex flex-col gap-1 mt-14">
                <Repeat count={3} element={Line} />
                <Line className="self-start max-w-[750px] w-full" />
            </div>
            <div className="mt-14 h-[58px] self-start max-w-[580px] w-full rounded-sm skeleton" />
        </div>
    )
}