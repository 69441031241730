import { formatLikes as formatRating } from "@/js/common";
import HeartIcon from "@/svg/heart-regular.svg?react";
import PlusIcon from "@/svg/plus-solid.svg?react";
import ShareNodesIcon from "@/svg/share-nodes-regular.svg?react";
import ThumbsDownIcon from "@/svg/thumbs-down-solid.svg?react";
import ThumbsUpIcon from "@/svg/thumbs-up-solid.svg?react";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import DotsDropdown, { DotsDropdownItem } from "./DotsDropdown";

interface ActionProps extends ClickableProps {
    active?: boolean,
    icon: React.ReactNode
}

function Action({className, active, icon, children, ...props}: ActionProps) {
    return (
        <Clickable className={classNames(`flex items-center gap-2.5 body-l-md hover:text-text-900 ${active ? "text-text-900 fill-primary-500" : "fill-neutral-700 hover:fill-neutral-800"}`, className)} {...props}>
            {icon}
            <span className="hidden sm:inline">{children}</span>
        </Clickable>
    )
}

interface RateActionProps extends Omit<ActionProps, "children"> {
    rating?: number
}

function RateAction({className, active, icon, rating, ...props}: RateActionProps) {
    const {i18n} = useTranslation();

    return (
        <Clickable className={classNames(`flex items-center gap-2.5 body-m ${active ? "fill-primary-500" : "fill-neutral-700 hover:fill-neutral-800"}`, className)} {...props}>
            {icon}
            {rating !== undefined && formatRating(i18n.language, rating)}
        </Clickable>
    )
}

export default function Actions({
    className,
    watchLater,
    onWatchLater,
    favorite,
    onFavorite,
    onShare,
    likes,
    dislikes,
    liked,
    disliked,
    onLike,
    onDislike,
    dropdownItems
}: {
    className?: string,
    watchLater?: boolean,
    onWatchLater?: () => void,
    favorite?: boolean,
    onFavorite?: () => void,
    onShare?: () => void,
    likes?: number,
    dislikes?: number,
    liked?: boolean,
    disliked?: boolean,
    onLike?: () => void,
    onDislike?: () => void,
    dropdownItems?: DotsDropdownItem[]
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("flex justify-between items-center gap-8", className)}>
            <div className="flex gap-8 items-center">
                {onWatchLater && (
                    <Action
                        onClick={onWatchLater}
                        active={watchLater}
                        icon={<PlusIcon className="w-4" />}
                    >
                        {t("actions.watchLater")}
                    </Action>
                )}
                {onFavorite && (
                    <Action
                        onClick={onFavorite}
                        active={favorite}
                        icon={<HeartIcon className="w-4.5 sm:w-5" />}
                    >
                        {favorite ? t("actions.favorite.active") : t("actions.favorite")}
                    </Action>
                )}
                {onShare && (
                    <Action
                        onClick={onShare}
                        icon={<ShareNodesIcon className="w-4 sm:w-4.5" />}
                    >
                        {t("actions.share")}
                    </Action>
                )}
            </div>
            <div className="flex items-center gap-12">
                <div className="flex items-center gap-10">
                    {onLike && (
                        <RateAction 
                            onClick={onLike} 
                            active={liked}
                            icon={<ThumbsUpIcon className="w-4.5 sm:w-5" />}
                            rating={likes}
                        />
                    )}
                    {onDislike && (
                        <RateAction
                            onClick={onDislike}
                            active={disliked}
                            icon={<ThumbsDownIcon className="w-4.5 sm:w-5" />}
                            rating={dislikes}
                        />
                    )}
                </div>
                {dropdownItems && <DotsDropdown items={dropdownItems} large />}
            </div>
        </div>
    )
}