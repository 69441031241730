import { now } from "@/js/common";
import { ImageSet } from "@/js/resources";
import FlagIcon from "@/svg/flag-regular.svg?react";
import HeartIcon from "@/svg/heart-regular.svg?react";
import ImagesIcon from "@/svg/images-solid.svg?react";
import { Clickable } from "@enymo/react-clickable-router";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useDayjs } from "../../providers/DayjsProvider";
import DotsDropdown from "./DotsDropdown";
import Info from "./Info";
import Likes from "./Likes";
import ReleaseDate from "./ReleaseDate";

export interface ImageSetCardProps extends Pick<ImageSet, "id" | "title" | "featured_model" | "previews" | "images_count" | "publish_at" | "likes" | "favorite"> {
    className?: string,
    onUpdate?: (data: {favorite?: boolean}) => void
}

export default function ImageSetCard({
    className,
    id,
    featured_model,
    previews,
    images_count,
    title,
    likes,
    publish_at,
    favorite,
    onUpdate
}: ImageSetCardProps) {
    assertNotNull(previews, "relation 'previews' must be loaded");

    const {t, i18n: {language: lng}} = useTranslation();
    const dayjs = useDayjs();
    const reportUrl = useMemo(() => {
        const searchParams = new URLSearchParams;
        searchParams.set("url", `/images/${id}`);
        return `/${lng}/report?${searchParams.toString()}`;
    }, [id, lng]);

    return (
        <div className={classNames("flex flex-col", className)}>
            <Clickable to={(publish_at === null || publish_at <= now) ? `/${lng}/images/${id}` : undefined} className="flex gap-2 h-48">
                <div className="relative flex-2">
                    <img className="object-cover rounded-lg h-full skeleton" src={previews[0].thumbnail.src} width={previews[0].thumbnail.width} height={previews[0].thumbnail.height} alt={previews[0].alt} />
                    {publish_at !== null && publish_at > now && <ReleaseDate className="absolute top-2 left-2" date={publish_at} />}
                    {(publish_at === null || publish_at <= now) && <Likes className="absolute left-2 bottom-2.5" likes={likes} />}
                    <Info className="absolute right-2 bottom-2.5 flex gap-1 items-center">
                        <ImagesIcon className="w-4 fill-neutral-900" />
                        {images_count}
                    </Info>
                </div>
                <div className="flex flex-col flex-1 gap-1.5">
                    {previews.slice(1, 3).map(({id, alt, thumbnail}) => (
                        <img key={id} className="flex-1 rounded-md object-cover skeleton" {...thumbnail} alt={alt} />
                    ))}
                </div>
            </Clickable>
            <div className="mt-2.5 mb-1.5 flex justify-between items-center gap-3">
                <Clickable to={(publish_at === null || publish_at <= now) ? `/${lng}/images/${id}` : undefined} className="text-ellipsis overflow-hidden whitespace-nowrap body-m-md text-text-900 group-hover:text-text-white">{title}</Clickable>
                {onUpdate && (
                    <DotsDropdown items={[{
                        icon: <HeartIcon className="w-4.5" />,
                        onClick: () => onUpdate?.({favorite: !favorite}),
                        children: favorite ? t("actions.favorite.active") : t("actions.favorite"),
                        variant: favorite ? "highlight" : "normal"
                    }, {
                        icon: <FlagIcon className="w-3.5" />,
                        to: reportUrl,
                        children: t("report")
                    }]} />
                )}
            </div>
            <div className={classNames("flex items-center", featured_model?.avatar ? "justify-between" : "justify-end")}>
                {featured_model?.avatar && (
                    <Link to={`/${lng}/models/${featured_model.id}`} className="flex items-center gap-1.5 group">
                        <img className="size-5 rounded-full object-cover" src={featured_model.avatar.src} width={featured_model.avatar.width} height={featured_model.avatar.height} />
                        <span className="max-w-40 whitespace-nowrap overflow-hidden text-ellipsis body-s-md text-text-700 group-hover:text-text-800">{featured_model.first_name} ({featured_model.age})</span>
                    </Link>
                )}
                {publish_at !== null && publish_at <= now && <span className="body-s text-text-600">{dayjs(publish_at).fromNow()}</span>}
            </div>
        </div>
    )
}