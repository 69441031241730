import classNames from "classnames";
import React from "react";

export default function VideoGrid({className, children}: {
    className?: string,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-x-4 lg:gap-x-5 gap-y-5 sm:gap-y-8", className)}>
            {children}
        </div>
    )
}