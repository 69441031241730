import classNames from "classnames";
import React from "react";

export default function Tag({className, children}: {
    className?: string,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("h-6.5 px-2 flex items-center rounded-md bg-neutral-300 body-m-md", className)}>
            {children}
        </div>
    )
}