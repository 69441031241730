import { useCategories } from "@/js/resources";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CategoriesComponent from "../components/pages/Categories";

export default function Categories() {
    const {i18n} = useTranslation();
    const [categories] = useCategories({params: useMemo(() => ({
        locale: i18n.language
    }), [i18n.language])});

    return (
        <CategoriesComponent
            categories={categories}
        />
    )
}