import { Form } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Button from "../form/Button";
import CheckboxInput from "../form/CheckboxInput";
import EMailInput from "../form/EmailInput";
import Input from "../form/Input";
import Link from "../Link";

export interface Submit {
    email: string,
    password: string,
    remember: boolean
}

export default function Login({className, onLogin}: {
    className?: string,
    onLogin: (data: Submit) => Promise<void>
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>();

    return (
        <div className={classNames("flex flex-col gap-10", className)}>
            <div className="flex flex-col gap-5">
                <h1 className="heading-2xl text-center">{t("login")}</h1>
                <h2 className="heading-m text-center">{t("login.subtitle")}</h2>
            </div>
            <div className="flex flex-col gap-6 items-center">
                <Form form={form} onSubmit={onLogin} className="rounded-xl bg-bg-300 shadow-offer flex flex-col px-5 sm:px-10 py-8 max-w-[480px] w-full">
                    <h3 className="heading-l text-center lg:text-left">{t("login")}</h3>
                    <EMailInput className="mt-9" options={{
                        required: t("input.email.required")
                    }} />
                    <Input className="mt-6" name="password" type="password" label={t("login.password")} options={{
                        required: t("input.password.required")
                    }} />
                    <div className="mt-5 flex items-center justify-between">
                        <CheckboxInput name="remember">{t("login.remember")}</CheckboxInput>
                        <Link className="body-m-md" to="/login/forgot-password">{t("login.forgotPassword")}</Link>
                    </div>
                    <Button className="mt-12" variant="primary" submit>{t("login")}</Button>
                </Form>
                <span className="body-l-md text-center">
                    <Trans i18nKey="login.toSignUp">
                        Not a member yet? <Link to="/sign-up">Sign up now</Link>
                    </Trans>
                </span>
            </div>
        </div>
    )
}