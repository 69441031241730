import { Form, SubmitHandler } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Button from "../form/Button";
import EMailInput from "../form/EmailInput";
import Input from "../form/Input";

export interface Submit {
    first_name: string,
    last_name: string,
    email: string,
    subject: string,
    message: string
}

export default function Contact({className, onSubmit, email}: {
    className?: string,
    onSubmit: SubmitHandler<Submit>,
    email?: string
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>({
        values: email !== undefined ? {
            email,
            first_name: "",
            last_name: "",
            subject: "",
            message: ""
        } : undefined
    })

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await onSubmit(data);
        form.reset();
    }

    return (
        <div className={classNames("flex flex-col gap-14", className)}>
            <h1 className="heading-2xl">{t("contact.title")}</h1>
            <p className="body-l">
                <Trans i18nKey="contact.text">
                    If you have any questions for suggestions regarding our online erotic system, your membership or one of our
                    products/topics, don't hesitate to message us.<br />
                    <br />
                    <strong className="font-medium">Customer support can be reached:</strong><br />
                    <br />
                    Monday - Friday from 8 AM to 8 PM<br />
                    Weekends from 12 PM to 6 PM
                </Trans>
            </p>
            <Form form={form} onSubmit={handleSubmit} className="flex flex-col gap-6 max-w-2xl w-full">
                <div className="flex flex-col gap-6 sm:flex-row sm:gap-3">
                    <Input className="sm:flex-1" name="first_name" label={t("contact.firstName")} options={{
                        required: t("contact.firstName.required")
                    }} />
                    <Input className="sm:flex-1" name="last_name" label={t("contact.lastName")} options={{
                        required: t("contact.lastName.required")
                    }} />
                </div>
                <EMailInput options={{
                    required: t("input.email.required")
                }} />
                <Input name="subject" label={t("contact.subject")} options={{
                    required: t("contact.subject.required")
                }} />
                <Input name="message" type="textarea" label={t("contact.message")} options={{
                    required: t("contact.message.required")
                }} rows={16} />
                <Button variant="primary" submit className="self-end mt-4">{t("contact.submit")}</Button>
            </Form>
        </div>
    )
}