import classNames from "classnames";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import useClientSize from "../hooks/ClientSizeHook";

export interface CarouselRef {
    next(): void,
    previous(): void
}

export interface CarouselProps {
    className?: string,
    gap: number,
    infinite?: boolean,
    repetitions?: number,
    itemWidth?: number,
    children: React.ReactElement[],
    infiniteChildren?: React.ReactElement[],
    autoplay?: number,
    onItemClicked?: (index: number, offset: {x: number, y: number}, size: {width: number, height: number}) => void
}

const Carousel = forwardRef<CarouselRef, CarouselProps>(({
    className,
    gap,
    infinite = false,
    repetitions = 1,
    itemWidth: itemWithProp,
    children,
    infiniteChildren,
    autoplay,
    onItemClicked
}, ref) => {
    const [wrapperRef, {width, height}] = useClientSize<HTMLDivElement>();
    const timeout = useRef<NodeJS.Timeout>();
    const mouseOver = useRef(false);

    const itemWidth = itemWithProp ?? width ?? 0;

    const [offset, setOffset] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [dragOffset, setDragOffset] = useState(0);
    const [dragging, setDragging] = useState(false);
    const transform = (offset + (infinite ? repetitions * children.length : 0)) * (itemWidth + gap);

    const handleSetOffset = useCallback((offset: React.SetStateAction<number>) => {
        setAnimating(true);
        setOffset(currentOffset => {
            const newOffset = typeof offset === "function" ? offset(currentOffset) : offset;
            timeout.current = setTimeout(() => {
                setAnimating(false);
                setOffset(((newOffset % children.length) + children.length) % children.length);
            }, 500);
            return newOffset;
        });
    }, [setAnimating, setOffset, timeout, children.length]);

    useEffect(() => () => clearTimeout(timeout.current), [timeout]);
    useEffect(() => {
        if (autoplay !== undefined) {
            const interval = setInterval(() => {
                if (!mouseOver.current) {
                    handleSetOffset(offset => offset + 1);
                }
            }, autoplay);
            return () => clearInterval(interval);
        }
    }, [mouseOver, autoplay, handleSetOffset]);

    const handlePointerDown: React.PointerEventHandler = e => {
        e.currentTarget.setPointerCapture(e.pointerId);
        setDragging(true);
    }

    const handlePointerUp: React.PointerEventHandler = e => {
        const clickedIndex = Math.floor(e.nativeEvent.offsetX / (itemWidth + gap));
        if (e.nativeEvent.offsetX < itemWidth * (clickedIndex + 1) + gap * clickedIndex && Math.abs(dragOffset) < 15) {
            onItemClicked?.(clickedIndex + offset, {
                x: e.nativeEvent.offsetX - itemWidth * clickedIndex - gap * clickedIndex,
                y: e.nativeEvent.offsetY
            }, {
                width: itemWidth,
                height: height ?? 0
            });
        }

        let delta = dragOffset / (itemWidth + gap);
        handleSetOffset(offset + Math.round(delta < 0 ? delta - 0.3 : delta + 0.3));
        setDragOffset(0);
        setDragging(false);
    }

    const handlePointerMove: React.PointerEventHandler = e => {
        if (dragging && !animating) {
            setDragOffset(dragOffset => dragOffset - e.movementX);
        }
    }

    useImperativeHandle(ref, () => ({
        next() {
            handleSetOffset(offset => offset + 1)
        },
        previous() {
            handleSetOffset(offset => offset - 1)
        }
    }), [ref, handleSetOffset]);

    return (
        <div
            ref={wrapperRef}
            className={classNames("select-none touch-none", className)}
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerMove={handlePointerMove}
            onPointerOver={() => {
                mouseOver.current = true;
            }}
            onPointerOut={() => {
                mouseOver.current = false;
            }}
        >
            <div className={classNames("w-full flex relative", {
                "transition-transform duration-500": animating
            })} style={{
                gap: `${gap}px`,
                transform: `translateX(${-(transform + dragOffset)}px)`
            }}>
                {infinite ? (
                    Array<void>(repetitions * 2 + 1).fill().map((_, index) => (
                        <React.Fragment key={index}>
                            {index === repetitions ? children : (infiniteChildren ?? children)}
                        </React.Fragment>
                    ))
                ) : children}
            </div>
        </div>
    )
});
export default Carousel;