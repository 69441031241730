import { StaticPageId, useStaticPages } from "@/js/resources";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import StaticComponent from "../components/pages/Static";

export default function Static({slug}: {
    slug: StaticPageId
}) {
    const {t, i18n} = useTranslation();
    const [page] = useStaticPages({
        id: slug,
        params: useMemo(() => ({
            locale: i18n.language
        }), [i18n.language])
    });

    return (
        <div className="px-4 py-24 flex justify-center">
            <Helmet>
                <title>{t(slug)} - {import.meta.env.VITE_APP_NAME}</title>
            </Helmet>
            <StaticComponent className="max-w-7xl w-full" text={page?.text ?? []} />
        </div>
    )
}