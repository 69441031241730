import { sortLinkedList } from "@/js/linkedList";
import { useUser } from "@/js/providers/UserProvider";
import { usePlans } from "@/js/resources";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { route } from "ziggy-js";
import SelectPlanComponent from "../components/pages/SelectPlan";

export default function SelectPlan() {
    const {i18n} = useTranslation();
    const {user} = useUser();
    const [plans] = usePlans();

    const sortedPlans = useMemo(() => [...plans.filter(plan => plan.visibility === "unlisted"), ...sortLinkedList(plans.filter(plan => plan.visibility === "public"))].filter(({id, period}) => id !== user?.plan?.id && (user?.plan?.period !== "yearly" || period === "yearly")), [plans]);

    return (
        <SelectPlanComponent
            className="py-24"
            offers={sortedPlans.map(({sid, trial_days, trial_price, ...props}) => ({
                ...props,
                trial_days: trial_days ?? undefined,
                trial_price: trial_price ?? undefined,
                url: route("subscriptions.join", {sid, locale: i18n.language})
            }))}
        />
    )
}