import { ListProps } from "@/js/types";
import React from "react";
import { useTranslation } from "react-i18next";
import PaginatedList from "./PaginatedList";
import VideoCard, { VideoCardProps } from "./VideoCard";
import VideoCardSkeleton from "./VideoCardSkeleton";
import VideoGrid from "./VideoGrid";

export type SortBy = "newest" | "most_watched" | "best_rated" | "longest";
export interface VideoListProps extends ListProps<SortBy, Omit<VideoCardProps, "onUpdate">> {
    onUpdate: (id: number, data: {favorite?: boolean, watch_later?: boolean}) => void
}

export default function VideoList({
    totalItems,
    itemsPerPage,
    items,
    page,
    onUpdate,
    ...props
}: VideoListProps) {
    const {t} = useTranslation();
    const totalPages = totalItems !== undefined ? Math.ceil(totalItems / itemsPerPage) : undefined;

    return (
        <PaginatedList totalPages={totalPages} page={page} sortByChoices={[{
            value: "newest",
            label: t("sortBy.newest")
        }, {
            value: "most_watched",
            label: t("sortBy.mostWatched")
        }, {
            value: "best_rated",
            label: t("sortBy.bestRated")
        }, {
            value: "longest",
            label: t("sortBy.longest")
        }]} {...props}>
            <VideoGrid>
                {items?.map(item => (
                    <VideoCard onUpdate={data => onUpdate(item.id, data)} key={item.id} {...item} />
                )) ?? Array<void>(page === totalPages ? ((totalItems ?? 0) % itemsPerPage) || itemsPerPage : itemsPerPage).fill().map((_, index) => (
                    <VideoCardSkeleton key={index} />
                ))}
            </VideoGrid>
        </PaginatedList>
    )
}