import { EmailRegex } from "@/js/common";
import React from "react";
import { RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Input, { InputProps } from "./Input";

export default function EMailInput({options, label, name = "email", ...props}: Omit<InputProps, "type">) {
    const {t} = useTranslation();

    return (
        <Input name={name} type="email" label={label ?? t("input.email")} options={{
            pattern: {
                value: EmailRegex,
                message: t("input.email.pattern")
            },
            ...options
        } as RegisterOptions} {...props} />
    )
}