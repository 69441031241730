import React from "react";
import VideoList from "../../components/VideoList";
import usePaginatedVideos from "../../hooks/PaginatedVideosHook";

export default function FavoriteVideos() {
    const {
        videos,
        loading,
        ...rest
    } = usePaginatedVideos({
        filter: "favorite"
    });

    return (
        <VideoList
            items={loading ? undefined : videos}
            {...rest}
        />
    )
}