import classNames from "classnames";
import React, { useRef } from "react";
import useBreakpoint from "../hooks/BreakpointHook";
import BannerCarousel from "./BannerCarousel";
import { CarouselProps } from "./Carousel";
import ModelCard, { ModelCardProps } from "./ModelCard";

export default function FeaturedModelCarousel({className, models}: {
    className?: string,
    models?: ModelCardProps[]
}) {
    const sm = useBreakpoint("sm");
    const refs = useRef<HTMLAnchorElement[]>([]);

    const handleItemClicked: CarouselProps["onItemClicked"] = (index, offset, size) => {
        if (sm) {
            const model = Math.floor(offset.x / size.width * 3);
            if (offset.x < ((size.width - 24) / 3) * (model + 1) + 24 * model) {
                refs.current[index * 3 + model].click();
            }
        }
        else {
            refs.current[index].click();
        }
    }

    return (
        <div className={classNames("relative flex justify-center overflow-hidden", className)}>
            <div className="hidden lg:block absolute -inset-y-28 -left-56 w-96 bg-bg-100 blur-3xl z-10" />
            <div className="hidden lg:block absolute -inset-y-28 -right-56 w-96 bg-bg-100 blur-3xl z-10" />
            <BannerCarousel className="max-w-7xl w-full" onItemClicked={handleItemClicked}>
                {sm ? models ? Array<void>(Math.floor(models.length / 3)).fill().map((_, index) => (
                    <div key={models.slice(3 * index, 3 * (index + 1)).map(({id}) => id).join("-")} className="w-full flex gap-3">
                        {models.slice(3 * index, 3 * (index + 1)).map((model, modelIndex) => (
                            <ModelCard ref={(node: HTMLAnchorElement) => {
                                refs.current[index * 3 + modelIndex] = node;
                            }} key={model.id} className="flex-1" {...model} />
                        ))}
                    </div>
                )) : [
                    <div key={0} className="w-full flex gap-3">
                        {Array<void>(3).fill().map((_, index) => (
                            <div key={index} className="aspect-card rounded-lg skeleton flex-1" />
                        ))}
                    </div>
                ] : (models?.map((model, index) => (
                    <ModelCard ref={(node: HTMLAnchorElement) => {
                        refs.current[index] = node;
                    }} key={model.id} className="w-full" {...model} />
                )) ?? [<div key={0} className="w-full aspect-card rounded-lg skeleton" />])}
            </BannerCarousel>
        </div>
    )
}