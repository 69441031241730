import classNames from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Link from "../Link";

export default function ForgotPasswordSubmitted({className, onBack}: {
    className?: string,
    onBack?: () => void
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("flex flex-col items-center", className)}>
            <h1 className="heading-2xl text-center">{t("forgotPassword.submitted.title")}</h1>
            <h2 className="heading-m mt-5 text-center">{t("forgotPassword.submitted.subtitle")}</h2>
            <span className="mt-9 body-l-md">
                <Trans i18nKey="forgotPassword.submitted.resend">
                    Didn't get anything? <Link onClick={onBack}>Request again</Link>
                </Trans>
            </span>
        </div>
    )
}