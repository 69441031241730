import { useCategories } from "@/js/resources";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router";
import Tab from "../components/Tab";

export default function Category() {
    const {i18n,t} = useTranslation();
    const lng = i18n.language;
    const id = Number(useParams().id);


    const [category] = useCategories({id, params: useMemo(() => ({
        locale: i18n.language
    }), [i18n.language])});
    
    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            {category && (
                <Helmet>
                    <title>{category.title} - {import.meta.env.VITE_APP_NAME}</title>
                </Helmet>
            )}
            <div className="max-w-7xl w-full flex flex-col gap-10">
                <h1 className="heading-2xl">{category?.title}</h1>
                <div className="flex gap-1.5">
                    <Tab to={`/${lng}/categories/${id}/videos`}>{t("videos")}</Tab>
                    <Tab to={`/${lng}/categories/${id}/images`}>{t("images")}</Tab>
                </div>
                <Outlet />
            </div>
        </div>
    )
}