import ChevronDown from "@/svg/chevron-down-regular.svg?react";
import { GlissadeInput, GlissadeInputProps, useGlissadeInput } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";

export type SelectProps = Omit<GlissadeInputProps, "type">

export default function Select({
    className,
    name,
    disabled,
    ...props
}: SelectProps) {
    const glissade = useGlissadeInput({name, disabled});

    return (
        <div className={classNames("relative", className)}>
            <GlissadeInput
                type="select"
                name={name}
                className="rounded-md bg-[#ECEBF11A] h-7 pl-2 pr-6 body-m-md text-text-800 w-full [&_option]:bg-bg-700 appearance-none cursor-pointer"
                {...glissade}
                {...props}
            />
            <ChevronDown className="w-3 fill-neutral-700 absolute top-1/2 -translate-y-1/2 right-2 pointer-events-none" />
        </div>
    )
}