import { Breakpoint, breakpoints } from "@/js/types";
import { useMemo } from "react";
import useScreenSize from "./ScreenSizeHook";

export default function useBreakpoint(): Breakpoint | undefined;
export default function useBreakpoint(breakpoint: Breakpoint): boolean;
export default function useBreakpoint(breakpoint?: Breakpoint): Breakpoint | undefined | boolean {
    const {width} = useScreenSize();
    return useMemo(
        () => breakpoint
            ? width >= breakpoints[breakpoint]
            : Object.entries(breakpoints).find(([, value]) => width >= value)?.[0] as Breakpoint | undefined,
        [width, breakpoint]
    )
}