import React from "react";
import { useParams } from "react-router";
import ImageSetList from "../components/ImageSetList";
import usePaginatedImageSets from "../hooks/PaginatedImageSetsHook";

export default function CategoryImages() {
    const id = Number(useParams().id);
    const {
        imageSets,
        loading,
        ...rest
    } = usePaginatedImageSets({category: id}, [id]);

    return (
        <ImageSetList
            items={loading ? undefined : imageSets}
            {...rest}
        />
    )
}