import classNames from "classnames";
import React from "react";

export default function Radio({className, selected, error}: {
    className?: string,
    selected?: boolean,
    error?: boolean
}) {
    return (
        <div className={classNames(`flex justify-center items-center size-6 rounded-full group ${selected ? "bg-primary-500" : `border bg-bg-200 peer-focus:border-primary-500 peer-checked:bg-primary-500 peer-checked:border-0 ${error ? "border-error-500" : "border-neutral-200"}`}`, className)}>
            <div className={classNames("size-2.5 rounded-full peer-checked:group-[]:block bg-white", {
                "hidden": !selected
            })} />
        </div>
    )
}