import classNames from "classnames";
import React from "react";

export default function Info({className, children}: {
    className?: string,
    children: React.ReactNode
}) {
    return (
        <div className={classNames("px-1 py-0.5 rounded-md bg-bg-900/25 text-xs text-text-900", className)}>
            {children}
        </div>
    )
}