import { RadioGroup as GlissadeRadioGroup, RadioGroupProps as GlissadeRadioGroupProps } from "@enymo/glissade";
import classNames from "classnames";
import React from "react";
import ContextError from "../ContextError";

interface RadioGroupProps<T extends string | number | boolean> extends GlissadeRadioGroupProps<T> {
    className?: string,
    label?: string,
    children: React.ReactNode
}

export default function RadioGroup<T extends string | number | boolean>({className, label, children, ...props}: RadioGroupProps<T>) {
    return (
        <div className={classNames("flex flex-col", className)}>
            {label && <label className="body-m-md mb-3">{label}</label>}
            <GlissadeRadioGroup {...props}>
                {children}
                <ContextError className="mt-2.5" />
            </GlissadeRadioGroup>
        </div>
    )
}