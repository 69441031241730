import { Form } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../form/Button";
import EMailInput from "../form/EmailInput";
import Link from "../Link";

export interface Submit {
    email: string
}

export default function ForgotPassword({className, onSubmitEmail}: {
    className?: string,
    onSubmitEmail: (data: Submit) => Promise<void>
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>();

    return (
        <div className={classNames("flex flex-col items-center gap-20", className)}>
            <div className="flex flex-col items-center gap-5">
                <h1 className="heading-2xl text-center">{t("forgotPassword.title")}</h1>
                <h2 className="heading-m text-center">{t("forgotPassword.subtitle")}</h2>
            </div>
            <div className="flex flex-col gap-6 max-w-[480px] w-full">
                <Form form={form} onSubmit={onSubmitEmail} className="flex flex-col gap-12 py-8 px-5 sm:px-10 rounded-xl bg-bg-300 shadow-offer">
                    <EMailInput placeholder={t("input.email.placeholder")} options={{
                        required: t("input.email.required")
                    }} />
                    <Button variant="primary" submit>{t("forgotPassword.submit")}</Button>
                </Form>
                <Link className="self-center" to="/login">{t("forgotPassword.toLogin")}</Link>
            </div>
        </div>
    )
}