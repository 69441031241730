import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router";
import { version } from "../../package.json";

export type SentryListener = (eventId: string, type: "frontend" | "backend") => void;
const listeners = new Set<SentryListener>();

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
    environment: import.meta.env.VITE_APP_ENV,
    release: `sextermedia@${version}`,
    integrations: [
        Sentry.reactRouterV7BrowserTracingIntegration({
            useEffect: useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
        }),
        Sentry.replayIntegration()
    ],
    tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    tracePropagationTargets: ["localhost", /^https:\/\/sentry\.internal\.enymo\.com\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    beforeSend: (event, hint) => {
        if (hint.originalException instanceof AxiosError && hint.originalException.response?.data.sentry_id) {
            for (const listener of listeners) {
                listener(hint.originalException.response.data.sentry_id, "backend");
            }
            return null;
        }
        else if (event.event_id !== undefined) {
            for (const listener of listeners) {
                listener(event.event_id, "frontend");
            }
        }
        return event;
    }
});

export function addSentryListener(listener: SentryListener) {
    listeners.add(listener);
}

export function removeSentryListener(listener: SentryListener) {
    listeners.delete(listener);
}