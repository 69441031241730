import EllipsisVertical from "@/svg/ellipsis-vertical-regular.svg?react";
import useOnClickOutside from "@enymo/react-click-outside-hook";
import { Clickable } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React, { useState } from "react";

export interface DotsDropdownItem {
    icon: React.ReactNode,
    children: React.ReactNode,
    to?: string,
    onClick?: () => void,
    variant?: "normal" | "highlight" | "disabled"
}

export default function DotsDropdown({className, items, large = false}: {
    className?: string,
    items: DotsDropdownItem[],
    large?: boolean
}) {
    const [open, setOpen] = useState(false);
    const ref = useOnClickOutside<HTMLDivElement>(() => setOpen(false), [setOpen]);

    return (
        <div ref={ref} className={classNames("relative", className)}>
            <button type="button" onClick={() => setOpen(!open)} className={classNames("rounded flex justify-center items-center", large ? "w-4.5 h-6.5" : "w-4 h-6", open ? "fill-neutral-600 bg-neutral-100" : large ? "fill-neutral-500 hover:fill-neutral-600" : "fill-neutral-300 hover:fill-neutral-400")}>
                <EllipsisVertical className={large ? "w-1.5 h-5" : "w-1"} />
            </button>
            {open && (
                <div className="absolute -right-1.5 top-[calc(100%+4px)] rounded-xl bg-bg-300 px-1.5 py-2 flex flex-col gap-1 z-20">
                    {items.map(({icon, children, variant = "normal", ...props}, index) => (
                        <Clickable key={index} className={classNames("rounded-md h-8 px-3 flex items-center gap-2.5 font-medium text-sm whitespace-nowrap", {
                            "text-text-800 fill-neutral-800 hover:bg-bg-800": variant === "normal",
                            "text-text-400 fill-neutral-500": variant === "disabled",
                            "text-text-800 hover:bg-bg-800 fill-primary-500": variant === "highlight"
                        })} {...props}>
                            <div className="flex justify-center w-4.5">
                                {icon}
                            </div>
                            {children}
                        </Clickable>
                    ))}
                </div>
            )}
        </div>
    )
}