import classNames from "classnames";
import React from "react";

export default function ModelOverviewSkeleton({className}: {
    className?: string
}) {
    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="rounded-sm skeleton max-w-[470px] w-full self-start h-8" />
            <div className="mt-8 rounded-lg aspect-video skeleton" />
            <div className="flex justify-between mt-7">
                <div className="w-[90px] sm:w-[480px] h-6 skeleton rounded-sm" />
                <div className="w-[220px] h-6 skeleton rounded-sm" />
            </div>
            <div className="mt-14 flex flex-col gap-1">
                {Array<void>(4).fill().map((_, index) => (
                    <div key={index} className={classNames("h-4.5 rounded-sm skeleton", {
                        "self-start max-w-[750px] w-full": index === 3
                    })} />
                ))}
            </div>
            <div className="mt-14 flex flex-col gap-1 self-start max-w-[480px] w-full">
                {Array<void>(6).fill().map((_, index) => (
                    <div key={index} className="rounded-sm h-6 skeleton" />
                ))}
            </div>
            <div className="mt-8 h-10 max-w-[620px] w-full self-start skeleton rounded-sm" />
        </div>
    )
}