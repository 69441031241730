import React from "react";
import { useTranslation } from "react-i18next";
import CategoryCard, { CategoryCardProps } from "../CategoryCard";

export default function Categories({categories}: {
    categories: CategoryCardProps[]
}) {
    const {t} = useTranslation();

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-10">
                <h1 className="heading-2xl">{t("categories")}</h1>
                <div className="flex flex-col gap-8">
                    {categories.map(category => <CategoryCard key={category.id} {...category} />)}
                </div>
            </div>
        </div>
    )
}