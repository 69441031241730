import { SubmitHandler } from "@enymo/react-form-component";
import axios from "axios";
import React from "react";
import { useSearchParams } from "react-router";
import { route } from "ziggy-js";
import ReportComponent, { Submit } from "../components/pages/Report";

export default function Report() {
    const [searchParams] = useSearchParams();
    const url = searchParams.get("url");

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await axios.post(route("report.submit"), {
            ...data,
            urls: data.urls.filter(url => url)
        });
    }

    return (
        <div className="px-4 py-24 flex justify-center">
            <div className="max-w-7xl w-full">
                <ReportComponent
                    urls={url === null ? undefined : [`https://www.sextermedia.com/${url}`]}
                    onSubmit={handleSubmit}
                />
            </div>
        </div>
    )
}