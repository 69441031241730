import { formatDuration, now } from "@/js/common";
import { Video } from "@/js/resources";
import ClockIcon from "@/svg/clock-regular.svg?react";
import FlagIcon from "@/svg/flag-regular.svg?react";
import HeartIcon from "@/svg/heart-regular.svg?react";
import { Clickable } from "@enymo/react-clickable-router";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { useDayjs } from "../../providers/DayjsProvider";
import DotsDropdown from "./DotsDropdown";
import Info from "./Info";
import Likes from "./Likes";
import ReleaseDate from "./ReleaseDate";

export interface VideoCardProps extends Pick<Video, "id" | "publish_at" | "duration" | "featured_model" | "featured_preview" | "likes" | "favorite" | "watch_later" | "title"> {
    className?: string,
    onUpdate?: (data: {favorite?: boolean, watch_later?: boolean}) => void
}

export default function VideoCard({
    className = "flex",
    id,
    featured_model,
    featured_preview,
    duration,
    likes,
    publish_at,
    favorite,
    watch_later,
    title,
    onUpdate
}: VideoCardProps) {
    assertNotNull(featured_preview, "relation 'featured_preview' must be loaded");

    const {t, i18n: { language: lng }} = useTranslation();
    const dayjs = useDayjs();
    const reportUrl = useMemo(() => {
        const searchParams = new URLSearchParams;
        searchParams.set("url", `videos/${id}`);
        return `/${lng}/report?${searchParams.toString()}`;
    }, [id, lng]);

    return (
        <div className={classNames("flex-col", className)}>
            <Clickable to={(publish_at === null || publish_at <= now) ? `/${lng}/videos/${id}` : undefined} className="relative">
                <img className="size-full rounded-lg aspect-video object-cover skeleton" src={featured_preview.thumbnail.src} width={featured_preview.thumbnail.width} height={featured_preview.thumbnail.height} alt={featured_preview.alt} />
                {(publish_at === null || publish_at <= now) && <Likes className="absolute bottom-2 left-2" likes={likes} />}
                <Info className="absolute bottom-2 right-2">{formatDuration(duration)}</Info>
                {publish_at !== null && publish_at > now && (
                    <ReleaseDate className="absolute top-2 left-2" date={publish_at} />
                )}
            </Clickable>
            <div className="mt-2 mb-1.5 flex items-start gap-4">
                <Clickable to={(publish_at === null || publish_at <= now) ? `/${lng}/videos/${id}` : undefined} className="flex-1 body-m-md text-text-900 group-hover:text-white line-clamp-2">{title}</Clickable>
                {onUpdate && (
                    <DotsDropdown items={[{
                        icon: <ClockIcon className="w-4.5" />,
                        children: t("actions.watchLater"),
                        onClick: () => onUpdate({watch_later: !watch_later}),
                        variant: watch_later ? "highlight" : "normal"
                    }, {
                        icon: <HeartIcon className="w-4.5" />,
                        children: favorite ? t("actions.favorite.active") : t("actions.favorite"),
                        onClick: () => onUpdate({favorite: !favorite}),
                        variant: favorite ? "highlight" : "normal"
                    }, {
                        icon: <FlagIcon className="w-3.5" />,
                        children: t("report"),
                        to: reportUrl
                    }]} />
                )}
            </div>
            <div className="flex gap-3 justify-end items-center">
                {featured_model?.avatar && (
                    <Link to={`/${lng}/models/${featured_model.id}`} className="flex flex-1 gap-1.5 items-center group">
                        <img className="size-5 rounded-full" src={featured_model.avatar.src} width={featured_model.avatar.width} height={featured_model.avatar.height} />
                        <span className="text-ellipsis overflow-hidden whitespace-nowrap body-s-md text-text-700 group-hover:text-text-800">{featured_model.first_name} ({featured_model.age})</span>
                    </Link>
                )}
                {publish_at !== null && publish_at <= now && <span className="body-s text-text-600">{dayjs(publish_at).fromNow()}</span>}
            </div>
        </div>
    )
}