import { formatDuration } from "@/js/common";
import { Video } from "@/js/resources";
import { Resolution, resolutions as resolutionTypes } from "@/js/types";
import DownloadIcon from "@/svg/download-regular.svg?react";
import FlagIcon from "@/svg/flag-regular.svg?react";
import PlayIcon from "@/svg/play-solid.svg?react";
import compare from "@enymo/comparison";
import { assertNotNull, requireNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Actions from "./Actions";
import Button from "./form/Button";
import Link from "./Link";
import OverviewInfo from "./OverviewInfo";
import VideoPlayer from "./VideoPlayer";

export interface VideoOverviewProps extends Omit<Video, "featured_model_id" | "publish_at" | "video_thumbnail" | "status" | "unpublished_at"> {
    className?: string,
    playing: boolean,
    requestedResolution: Resolution | null,
    onPlay: () => Promise<void> | void,
    onChangeResolution: (resolution: Resolution) => void,
    onUpdate: (update: Partial<Pick<Video, "rating" | "favorite" | "watch_later">>) => void,
    onDownload: () => void
}

export default function VideoOverview({
    className,
    id,
    title,
    description,
    category,
    category_id,
    likes,
    dislikes,
    rating,
    favorite,
    watch_later,
    duration,
    featured_preview,
    models,
    resolutions,
    highest_resolution,
    requestedResolution,
    playing,
    downloadable,
    onPlay,
    onChangeResolution,
    onUpdate,
    onDownload
}: VideoOverviewProps) {
    assertNotNull(category, "relation 'category' must be loaded");
    assertNotNull(featured_preview, "relation 'featured_preview' must be loaded");
    assertNotNull(models, "relation 'models' must be loaded'");


    const {t, i18n: {language: lng}} = useTranslation();
    const reportUrl = useMemo(() => {
        const searchParams = new URLSearchParams;
        searchParams.set("url", `videos/${id}`);
        return `/${lng}/report?${searchParams.toString()}`;
    }, [id, lng]);
    
    const resolution = useMemo(
        () => requireNotNull((requestedResolution && resolutions?.find(({resolution}) => resolution === requestedResolution)) ?? resolutions?.find(({resolution}) => resolution === highest_resolution), "unable to determine playback resolution"),
        [resolutions, requestedResolution, highest_resolution]
    );
    const availableResolutions = useMemo(() => resolutions?.map(({resolution}) => resolution).toSorted((a, b) => compare(b, a, resolutionTypes as unknown as Resolution[])), [resolutions]);

    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="flex flex-col gap-8">
                <h1 className="heading-xl">{title}</h1>
                {playing ? (
                    <VideoPlayer
                        key={id}
                        className="rounded-lg overflow-hidden aspect-video"
                        src={resolution.url}
                        resolution={resolution.resolution}
                        resolutions={availableResolutions}
                        onChangeResolution={onChangeResolution}
                    />
                ) : (
                    <div className="relative">
                        <img className="rounded-lg aspect-video object-cover skeleton w-full" {...featured_preview.preview} alt={featured_preview.alt} />
                        <div className="absolute inset-0 flex justify-center items-center">
                            <Button variant="play" onClick={onPlay} innerClassName="flex items-center gap-2">
                                <PlayIcon className="h-5 fill-primary-800" />
                                {t("video.play")}
                            </Button>
                        </div>
                    </div>
                )}
                <Actions
                    liked={rating === "like"}
                    disliked={rating === "dislike"}
                    likes={likes}
                    dislikes={dislikes}
                    onLike={() => onUpdate({rating: rating === "like" ? null : "like"})}
                    onDislike={() => onUpdate({rating: rating === "dislike" ? null : "dislike"})}
                    favorite={favorite}
                    onFavorite={() => onUpdate({favorite: !favorite})}
                    onWatchLater={() => onUpdate({watch_later: !watch_later})}
                    watchLater={watch_later}
                    dropdownItems={[{
                        icon: <DownloadIcon className="w-4" />,
                        onClick: onDownload,
                        variant: downloadable ? "normal" : "disabled",
                        children: t("download")
                    }, {
                        icon: <FlagIcon className="w-3.5" />,
                        to: reportUrl,
                        children: t("report")
                    }]}
                />
            </div>
            <p className="body-m mt-14 mb-10">{description}</p>
            <div className="self-start grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-4">
                <OverviewInfo style={{gridRow: `span ${models.length}`}} title={t("video.models")}>
                    <div className="flex flex-col gap-4">
                        {models.map(({id, first_name, age, avatar}) => (
                            <div key={id} className="flex gap-1.5 items-center">
                                <img className="size-5 rounded-full" {...avatar} id={undefined} />
                                <Link className="body-m" to={`/${lng}/models/${id}`}>{first_name} ({age})</Link>
                            </div>
                        ))}
                    </div>
                </OverviewInfo>
                <OverviewInfo title={t("video.duration")}>{formatDuration(duration)}</OverviewInfo>
                <OverviewInfo title={t("video.category")}>
                    <Link to={`/${lng}/categories/${category_id}`}>{category.title}</Link>
                </OverviewInfo>
                <OverviewInfo title={t("video.resolution")}>{t(`resolution.${highest_resolution}`)}</OverviewInfo>
            </div>
        </div>
    )
}