import MagnifyingGlassIcon from "@/svg/magnifying-glass-regular.svg?react";
import classNames from "classnames";
import React from "react";

export default function Search({
    className,
    onChange,
    placeholder
}: {
    className?: string,
    onChange?: (value: string) => void,
    placeholder?: string
}) {
    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
        if (e.key === "Enter") {
            onChange?.(e.currentTarget.value);
        }
    }

    return (
        <div className={classNames("relative", className)}>
            <input
                type="text"
                className="h-9 w-full rounded-lg pr-2.5 pl-[34px] bg-bg-900/30 hover:bg-neutral-400/30 focus:bg-neutral-400/30 focus:border focus:border-neutral-900 text-white text-lg placeholder:text-text-600"
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
            />
            <MagnifyingGlassIcon className="fill-neutral-900 size-4 absolute top-1/2 -translate-y-1/2 left-2.5" />
        </div>
    )
}