import { Model } from "@/js/resources";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import Likes from "./Likes";

export interface ModelCardProps extends Pick<Model, "id" | "alt" | "first_name" | "age" | "description" | "likes" | "preview_thumbnail"> {
    className?: string
}

const ModelCard = forwardRef<HTMLAnchorElement, ModelCardProps>(({
    className,
    id,
    age,
    alt,
    description,
    first_name,
    preview_thumbnail,
    likes
}: ModelCardProps, ref) => {
    assertNotNull(preview_thumbnail, "relation 'preview_thumbnail' must be loaded");
    const {i18n} = useTranslation();
    const lng = i18n.language;

    return (
        <Link ref={ref} draggable={false} to={`/${lng}/models/${id}`} className={classNames("relative aspect-card group rounded-lg overflow-hidden", className)}>
            <img draggable={false} className="size-full shadow-card object-cover skeleton" src={preview_thumbnail.src} width={preview_thumbnail.width} height={preview_thumbnail.height} alt={alt} />
            <div className="absolute bottom-0 left-0 w-full px-2.5 pt-4 pb-2.5 bg-gradient-to-b via-25% from-transparent via-[#1C1B2875] to-bg-500 flex flex-col gap-2 body-l-md group-hover:text-white">
                {first_name} ({age}) {description}
                <Likes likes={likes} />
            </div>
        </Link>
    )
});
export default ModelCard;