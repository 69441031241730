import React from "react";
import { useTranslation } from "react-i18next";
import VideoList from "../components/VideoList";
import usePaginatedVideos from "../hooks/PaginatedVideosHook";

export default function WatchLater() {
    const {t} = useTranslation();
    const {
        videos,
        loading,
        ...rest
    } = usePaginatedVideos({
        filter: "watch-later"
    });

    return (
        <div className="flex justify-center px-4 py-10 lg:py-20">
            <div className="max-w-7xl w-full flex flex-col gap-12">
                <h1 className="heading-2xl">{t("watchLater")}</h1>
                <VideoList
                    items={loading ? undefined : videos}
                    {...rest}
                />
            </div>
        </div>
    )
}