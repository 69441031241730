import { useToaster } from "@/js/main/providers/ToasterProvider";
import { Form, SubmitHandler } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Button from "../../form/Button";
import Input from "../../form/Input";

export interface Submit {
    current_password: string,
    new_password: string
}

export default function Password({className, onSubmit}: {
    className?: string,
    onSubmit: (data: Submit) => Promise<void>
}) {
    const {t} = useTranslation();
    const form = useForm<Submit>();
    const toast = useToaster();

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await onSubmit(data);
        form.reset();
        toast({
            variant: "success",
            title: t("changePassword.success.title"),
            text: t("changePassword.success.text"),
            duration: 3000
        });
    }

    return (
        <Form form={form} onSubmit={handleSubmit} className={classNames("flex flex-col", className)}>
            <h1 className="heading-l">{t("account.password")}</h1>
            <Input className="mt-6 md:mt-14" type="password" name="current_password" label={t("account.password.currentPassword")} options={{
                required: t("account.password.currentPassword.required")
            }} />
            <Input className="mt-6" type="password" name="new_password" label={t("account.password.newPassword")} options={{
                required: t("input.password.required"),
                minLength: {
                    value: 8,
                    message: t("input.password.minLength")
                }
            }} />
            <Button className="self-end mt-9 md:mt-12" variant="primary" submit>{t("account.password.submit")}</Button>
        </Form>
    )
}