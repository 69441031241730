import classNames from "classnames";
import React from "react";

export default function VideoCardSkeleton({className}: {
    className?: string
}) {
    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="rounded-lg aspect-video skeleton" />
            <div className="mt-2 mx-1.5 max-w-[260px] h-4.5 skeleton rounded-sm" />
            <div className="mt-0.5 mx-1.5 max-w-[200px] h-4.5 skeleton rounded-sm" />
            <div className="mx-1.5 mt-2 flex gap-4 items-center">
                <div className="flex-1 flex gap-2 items-center">
                    <div className="size-5 rounded-full skeleton" />
                    <div className="flex-1 h-3.5 rounded-sm skeleton" />
                </div>
                <div className="rounded-sm h-3.5 skeleton w-[90px]" />
            </div>
        </div>
    )
}