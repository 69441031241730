import { Model } from "@/js/resources";
import { assertNotNull } from "@enymo/ts-nullsafe";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import Actions from "./Actions";
import OverviewInfo from "./OverviewInfo";
import Tag from "./Tag";

export interface ModelOverviewProps extends Omit<Model, "last_name" | "publish_at"> {
    className?: string,
    onUpdate: (update: Partial<Pick<Model, "rating" | "favorite">>) => void
}

export default function ModelOverview({
    className,
    alt,
    first_name,
    age,
    description,
    preview,
    rating,
    likes,
    dislikes,
    favorite,
    bio,
    cup_size,
    genital_area,
    height,
    nationality,
    preferences,
    weight,
    zodiac,
    onUpdate
}: ModelOverviewProps) {
    assertNotNull(preview, "relation 'preview' must be loaded");

    const {t, i18n} = useTranslation();

    return (
        <div className={classNames("flex flex-col", className)}>
            <div className="flex flex-col gap-8">
                <h1 className="heading-xl">{first_name} ({age}) {description}</h1>
                <img className="rounded-lg w-auto aspect-video object-cover skeleton" src={preview.src} width={preview.width} height={preview.height} alt={alt} />
                <Actions
                    liked={rating === "like"}
                    disliked={rating === "dislike"}
                    likes={likes}
                    dislikes={dislikes}
                    onLike={() => onUpdate({rating: rating === "like" ? null : "like"})}
                    onDislike={() => onUpdate({rating: rating === "dislike" ? null : "dislike"})}
                    favorite={favorite}
                    onFavorite={() => onUpdate({favorite: !favorite})}
                />
            </div>
            <p className="body-m mt-14 mb-10">{bio}</p>
            <h3 className="heading-s">{t("model.aboutMe")}</h3>
            <div className="self-start grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-4 mt-5 mb-6">
                <OverviewInfo title={t("model.age")}>{age}</OverviewInfo>
                <OverviewInfo title={t("model.nationality")}>{t(`nationality.${nationality}`)}</OverviewInfo>
                <OverviewInfo title={t("model.weight")}>{t("model.weight.value", {weight})}</OverviewInfo>
                <OverviewInfo title={t("model.zodiac")}>{t(`zodiac.${zodiac}`)}</OverviewInfo>
                <OverviewInfo title={t("model.height")}>
                    {t("model.height.value", {height: (height / 100).toLocaleString(i18n.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })})}
                </OverviewInfo>
                <OverviewInfo title={t("model.genitalArea")}>{t(`genitalArea.${genital_area}`)}</OverviewInfo>
                <OverviewInfo title={t("model.cupSize")}>{cup_size}</OverviewInfo>
            </div>
            <h2 className="heading-s">{t("model.preferences")}</h2>
            <div className="mt-5 flex flex-wrap gap-2">
                {preferences.map((preference, index) => <Tag key={index}>{t(`preferences.${preference}`)}</Tag>)}
            </div>
        </div>
    )
}