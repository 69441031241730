import { useUser } from "@/js/providers/UserProvider";
import { useImageSets } from "@/js/resources";
import { isNotNull } from "@enymo/ts-nullsafe";
import React, { useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { route } from "ziggy-js";
import { ImageSetOverviewProps } from "../components/ImageSetOverview";
import ImageSetComponent from "../components/pages/ImageSet";
import { useToaster } from "../providers/ToasterProvider";

export default function ImageSet() {
    const {t, i18n} = useTranslation();
    const {user} = useUser();
    const location = useLocation();
    const navigate = useNavigate();
    const toast = useToaster();
    const id = Number(useParams().id);
    const [imageSet, {update}] = useImageSets({id, params: useMemo(() => ({
        locale: i18n.language,
        with: ["images", "previews", "models", "category", "rating", "downloadable"]
    }), [i18n.language])});
    const [recommendations, {loading: recommendationsLoading}] = useImageSets({params: useMemo(() => ({
        locale: i18n.language,
        filter: "random",
        limit: 3,
        not: id,
        with: ["previews", "featured_model"]
    }), [id])});

    const handleUpdate: ImageSetOverviewProps["onUpdate"] = data => {
        if (user === null) {
            const searchParams = new URLSearchParams;
            searchParams.set("redirect", location.pathname);
            navigate(`/login?${searchParams.toString()}`);
        }
        else {
            return update(data, "immediate");
        }
    }

    const handleDownload = async () => {
        if (!imageSet?.downloadable) {
            toast({
                duration: 3000,
                variant: "error",
                title: t("download.disallowed"),
                text: !isNotNull(user?.plan) ? (
                    t("download.disallowed.noMember")
                ) : !user.plan.features.includes("downloads") ? (
                    t("download.disallowed.wrongPlan")
                ) : t("download.disallowed.overLimit")
            });
        }
        else {
            toast({
                duration: 3000,
                variant: "success",
                title: t("download.starting"),
                text: t("download.starting.text")
            });
            window.location.href = route("image-sets.download", {image_set: id});
        }
    }

    return <>
        {imageSet && (
            <Helmet>
                <title>{imageSet.title} - {import.meta.env.VITE_APP_NAME}</title>
            </Helmet>
        )}
        <ImageSetComponent
            imageSet={imageSet ?? undefined}
            recommendations={recommendationsLoading ? undefined : recommendations}
            onUpdate={handleUpdate}
            onDownload={handleDownload}
            isMember={isNotNull(user?.plan) || user?.role === "admin" || user?.role === "permanentAccess"}
        />
    </>
}