import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";

export default function Link({
    className,
    ...props
}: ClickableProps) {
    return (
        <Clickable className={classNames("text-primary-600 hover:text-primary-700", className)} {...props} />
    )
}