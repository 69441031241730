import "@/css/main.css";
import "@/js/common";
import de from "@/lng/main/de.json";
import en from "@/lng/main/en.json";
import es from "@/lng/main/es.json";
import fr from "@/lng/main/fr.json";
import it from "@/lng/main/it.json";
import "dayjs/locale/de";
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/fr";
import "dayjs/locale/it";
import i18next from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { Locale, locales } from "../types";

export function getPreferredLanguage(): Locale | null {
    return navigator.languages.find(lng => locales.includes(lng as any)) as Locale | undefined ?? null;
}

i18next
    .use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            convertDetectedLanguage: lng => {
                const index = lng.indexOf("-");
                return index === -1 ? lng : lng.substring(0, index);
            },
            order: ["htmlTag", "localStorage"]
        },
        debug: import.meta.env.VITE_APP_DEBUG === "true",
        fallbackLng: ["de", "en"],
        resources: {
            de: {
                translation: de
            },
            en: {
                translation: en
            },
            fr: {
                translation: fr
            },
            it: {
                translation: it
            },
            es: {
                translation: es
            }
        },
        react: {
            useSuspense: false
        },
        interpolation: {
            escapeValue: false
        }
    });