import CrownIcon from "@/svg/crown-duotone-solid.svg?react";
import LockIcon from "@/svg/lock-solid.svg?react";
import { Clickable, ClickableProps } from "@enymo/react-clickable-router";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

interface AccountMenuItemProps extends ClickableProps {
    active?: boolean,
    icon: React.ReactNode
}

function AccountMenuItem({className, to, active: activeProp, icon, children, ...props}: AccountMenuItemProps) {
    const location = useLocation();
    const active = activeProp ?? (to && location.pathname.startsWith(to));

    return (
        <Clickable to={to} {...props} className={classNames(`h-9 rounded-md px-3.5 flex items-center gap-3.5 body-m-md fill-neutral-800 ${active ? "bg-bg-800" : "hover:bg-bg-900/60"}`, className)}>
            <div className="flex justify-center w-5">
                {icon}
            </div>
            {children}
        </Clickable>
    )
}

export default function AccountMenu({className}: {
    className?: string
}) {
    const {t} = useTranslation();

    return (
        <div className={classNames("rounded-xl bg-bg-200/80 px-5 sm:px-10 py-8 flex flex-col gap-8", className)}>
            <h2 className="heading-m">{t("user.account")}</h2>
            <div className="flex flex-col gap-2">
                <AccountMenuItem to="/account/password" icon={<LockIcon className="w-4" />}>{t("account.menu.password")}</AccountMenuItem>
                <AccountMenuItem to="/account/plan" icon={<CrownIcon className="w-5" />}>{t("account.menu.plan")}</AccountMenuItem>
            </div>
        </div>
    )
}