import { useUser } from "@/js/providers/UserProvider";
import { SubmitHandler } from "@enymo/react-form-component";
import axios from "axios";
import React from "react";
import { useTranslation } from "react-i18next";
import { route } from "ziggy-js";
import ContactComponent, { Submit } from "../components/pages/Contact";
import { useToaster } from "../providers/ToasterProvider";

export default function Contact() {
    const {t} = useTranslation();
    const {user} = useUser();
    const toast = useToaster();

    const handleSubmit: SubmitHandler<Submit> = async data => {
        await axios.post(route("contact.send"), data);
        toast({
            duration: 5000,
            title: t("contact.sent.title"),
            text: t("contact.sent.text"),
            variant: "success"
        });
    }

    return (
        <div className="px-4 py-24 flex justify-center">
            <ContactComponent
                className="max-w-7xl w-full"
                onSubmit={handleSubmit}
                email={user?.email}
            />
        </div>
    )
}