export interface TextNode {
    text: string,
    type: 'text',
    bold?: true,
    underline?: true,
    italic?: true,
    strikethrough?: true
}

export interface LinkNode {
    type: 'link',
    url: string,
    children: TextNode[]
}

export interface ListItemNode {
    type: 'list-item';
    children: (TextNode | LinkNode)[];
}

export interface ListNode {
    type: 'list',
    format: "ordered" | "unordered",
    children: (ListNode | ListItemNode)[]
}

export interface HeadingNode {
    type: "heading",
    level: number,
    children: (TextNode | LinkNode)[];
}

export interface BlockNode {
    type: 'paragraph' | 'quote';
    children: (TextNode | LinkNode)[];
}

export type RichTextNode = ListNode | BlockNode | HeadingNode;

export interface QuillDelta {
    attributes?: {
        bold?: true,
        italic?: true,
        strike?: true,
        underline?: true,
        header?: number,
        list?: "ordered" | "bullet",
        indent?: number,
        link?: string
    },
    insert: string
}

function isPlainText(node: TextNode) {
    return node.bold === undefined 
        && node.italic === undefined 
        && node.underline === undefined 
        && node.strikethrough === undefined;
}

export function quillDeltaToRichText(deltas: QuillDelta[]): RichTextNode[] {
    const stack: RichTextNode[] = [];
    let line: (TextNode | LinkNode)[] = [];

    for (const delta of deltas) {
        if (delta.insert === "\n" && delta.attributes !== undefined) {
            if (delta.attributes.header !== undefined) {
                stack.push({
                    type: "heading",
                    level: delta.attributes.header,
                    children: line
                });
            }
            else if (delta.attributes.list !== undefined) {
                let insert: (RichTextNode | ListItemNode)[] = stack;
                for (let i = 0; i <= (delta.attributes.indent ?? 0); i++) {
                    let last = insert.at(-1);
                    if (last?.type !== "list" || ((last.format === "ordered") !== (delta.attributes.list === "ordered"))) {
                        last = {
                            type: "list",
                            format: delta.attributes.list === "ordered" ? "ordered" : "unordered",
                            children: []
                        }
                        insert.push(last);   
                    }
                    insert = last.children;
                }
                insert.push({
                    type: "list-item",
                    children: line
                });
            }
            else {
                throw new Error("Failed to parse declarative newline. No instructions recognized");
            }
            line = [];
        }
        else {
            for (const [index, text] of delta.insert.split("\n").entries()) {
                if (index > 0) {
                    const stackLast = stack.at(-1);
                    if (stackLast?.type !== "paragraph") {
                        stack.push({
                            type: "paragraph",
                            children: line
                        });
                    }
                    else {
                        let last = stackLast.children.at(-1);
                        if (last?.type !== "text" || !isPlainText(last)) {
                            last = {
                                type: "text",
                                text: "\n"
                            };
                            stackLast.children.push(last);
                        }
                        else {
                            last.text += "\n";
                        }

                        if (line[0]?.type === "text" && isPlainText(line[0])) {
                            last.text += line[0].text;
                            line.shift();
                        }

                        stackLast.children.push(...line);
                    }
                    line = [];
                }
                if (text !== "") {
                    const node: TextNode = {
                        type: "text",
                        text
                    };
                    if (delta.attributes?.bold) {
                        node.bold = true;
                    }
                    if (delta.attributes?.italic) {
                        node.italic = true;
                    }
                    if (delta.attributes?.strike) {
                        node.strikethrough = true;
                    }
                    if (delta.attributes?.underline) {
                        node.underline = true;
                    }

                    if (delta.attributes?.link) {
                        let last = line.at(-1);
                        if (last?.type !== "link" || last.url !== delta.attributes.link) {
                            last = {
                                type: "link",
                                url: delta.attributes.link,
                                children: []
                            };
                            line.push(last);
                        }
                        last.children.push(node);
                    }
                    else {
                        line.push(node);
                    }
                }
            }
        }
    }

    return stack;
}