import { formatLikes } from "@/js/common";
import ThumbsUpIcon from "@/svg/thumbs-up-solid.svg?react";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Likes({className, likes}: {
    className?: string,
    likes: number
}) {
    const {i18n} = useTranslation();

    return (
        <div className={classNames("flex items-center gap-1 font-medium text-xs text-text-900", className)}>
            <ThumbsUpIcon className="w-4 fill-primary-800" />
            {formatLikes(i18n.language, likes)}
        </div>
    )
}