'use client';

import { LinkNode, ListItemNode, RichTextNode, TextNode } from "@/js/richText";
import { Clickable } from "@enymo/react-clickable-router";
import React from "react";
import LinebreakText from "./LinebreakText";

export default function RichText({className, children, ...props}: {
    className?: string,
    openLinksInNewTab?: boolean,
    children: (RichTextNode | TextNode | LinkNode | ListItemNode)[],
    liElement?: React.ReactNode
}) {
    return children.map((node, index) => {
        switch (node.type) {
            case "heading":
                return (
                    React.createElement(`h${node.level}`, {
                        key: index,
                        className,
                        children: <RichText>{node.children}</RichText>,
                        ...props
                    })
                )
            case "paragraph":
                return (
                    <p className={className} key={index}>
                        <RichText {...props}>{node.children}</RichText>
                    </p>
                )
            case "list":
                return node.format === "ordered" ? (
                    <ol key={index}>
                        <RichText {...props}>{node.children}</RichText>
                    </ol>
                ) : (
                    <ul key={index}>
                        <RichText {...props}>{node.children}</RichText>
                    </ul>
                )
            case "list-item":
                return props.liElement ? (
                    <li key={index} className="flex items-start gap-4">
                        {props.liElement}
                        <span>
                            <RichText>{node.children}</RichText>
                        </span>
                    </li>
                ) : (
                    <li key={index}>
                        <RichText {...props}>{node.children}</RichText>
                    </li>
                )
            case "link":
                const match = node.url.match(/^internal:(.+)$/);
                return (
                    <Clickable key={index} to={match === null ? node.url : match[1]} linkType={props.openLinksInNewTab ? "new-tab" : match === null ? "no-router" : "normal"}>
                        <RichText {...props}>{node.children}</RichText>
                    </Clickable>
                )
            case "text":
                let output = <LinebreakText>{node.text}</LinebreakText>
                if (node.bold) {
                    output = <b>{output}</b>
                }
                if (node.italic) {
                    output = <i>{output}</i>
                }
                if (node.underline) {
                    output = <u>{output}</u>
                }
                if (node.strikethrough) {
                    output = <s>{output}</s>
                }
                return (
                    <React.Fragment key={index}>{output}</React.Fragment>
                );
            case "quote":
                return (
                    <blockquote key={index}>
                        <RichText {...props}>{node.children}</RichText>
                    </blockquote>
                )
            default:
                throw new Error(`Rich text element not implemented: '${(node as any).type}'`);
        }
    })
}