import { sortLinkedList } from "@/js/linkedList";
import { useUser } from "@/js/providers/UserProvider";
import { useBannerImages, useCategories, useModels, useVideos } from "@/js/resources";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import HomeComponent from "../components/pages/Home";

const topModelsParams = {
    sort_by: {name: "likes", direction: "desc"},
    limit: 9
}

export default function Home() {
    const {i18n} = useTranslation();
    const {user} = useUser();
    const navigate = useNavigate();
    const [banner, {loading: bannerLoading}] = useBannerImages({params: useMemo(() => ({
        locale: i18n.language
    }), [i18n.language])});

    const [topBannerImages, bottomBannerImages] = useMemo(() => [
        banner.filter(({position}) => position === "top"),
        banner.filter(({position}) => position === "bottom")
    ], [banner]);

    const [categories, {loading: categoriesLoading}] = useCategories();
    const sortedCategories = useMemo(() => sortLinkedList(categories), [categories]);

    const [latestVideos, {update: updateLatest, loading: latestVideosLoading}] = useVideos({params: useMemo(() => ({
        sort_by: {name: "publish_at", direction: "desc"},
        limit: 8,
        with: ["featured_preview", "featured_model", "rating"],
        locale: i18n.language
    }), [i18n.language])});
    const [popularVideos, {update: updatePopular, loading: popularVideosLoading}] = useVideos({params: useMemo(() => ({
        sort_by: {name: "likes", direction: "desc"},
        limit: 16,
        with: ["featured_preview", "featured_model", "rating"],
        locale: i18n.language
    }), [i18n.language])});
    const [featuredModels, {loading: featuredModelsLoading}] = useModels({params: useMemo(() => ({
        sort_by: {name: "likes", direction: "desc"},
        limit: 9,
        locale: i18n.language
    }), [i18n.language])});

    const handleUpdate = (update: (id: number, data: {favorite?: boolean, watch_later?: boolean}, method: "immediate") => void) => (id: number, data: {favorite?: boolean, watch_later?: boolean}) => {
        if (user === null) {
            navigate("/login");
        }
        else {
            update(id, data, "immediate");
        }
    }

    return (
        <HomeComponent
            topBannerImages={bannerLoading ? undefined : topBannerImages}
            bottomBannerImages={bannerLoading ? undefined : bottomBannerImages}
            featuredCategories={categoriesLoading ? undefined : sortedCategories}
            latestVideos={latestVideosLoading ? undefined : latestVideos}
            popularVideos={popularVideosLoading ? undefined : popularVideos}
            featuredModels={featuredModelsLoading ? undefined : featuredModels}
            onUpdateLatestVideo={handleUpdate(updateLatest)}
            onUpdatePopularVideo={handleUpdate(updatePopular)}
        />
    )
}