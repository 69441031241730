import React from "react";
import { Outlet } from "react-router";
import AccountMenu from "../../AccountMenu";

export default function Account({className, children}: {
    className?: string
    children?: React.ReactNode
}) {
    return (
        <div className="px-4 py-10 sm:py-20 justify-center flex">
            <div className="max-w-7xl w-full flex flex-col gap-9 md:flex-row md:gap-14 md:items-start">
                <AccountMenu className="md:max-w-[300px] w-full" />
                <div className="flex-1 rounded-xl bg-bg-300 px-5 sm:px-10 py-7 sm:py-8">
                    {children ?? <Outlet />}
                </div>
            </div>
        </div>
    )
}