import { Offer } from "@/js/resources";
import CrownIcon from "@/svg/crown-duotone-solid.svg?react";
import { Form } from "@enymo/react-form-component";
import classNames from "classnames";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import Button from "../form/Button";
import EMailInput from "../form/EmailInput";
import Input from "../form/Input";
import Link from "../Link";
import OfferComponent from "../Offer";
import USPs from "../USPs";

export interface Submit {
    email: string,
    password: string
}

export default function SignUp({
    className,
    redirect,
    offers,
    onSignUp
}: {
    className?: string,
    redirect?: string,
    offers: Offer[],
    onSignUp: (data: Submit) => Promise<void>
}) {
    const {t, i18n: {language: lng}} = useTranslation();
    const form = useForm<Submit>();

    return (
        <div className={classNames("flex flex-col gap-10 sm:gap-20", className)}>
            <div className="flex flex-col gap-6 items-center">
                <h1 className="flex items-center gap-2.5 heading-2xl fill-white">
                    <CrownIcon className="w-9" />
                    {t("signUp.title")}
                </h1>
                <h2 className="heading-m text-center">{t("signUp.subtitle")}</h2>
            </div>
            <div className="flex flex-col-reverse justify-center items-center lg:items-start lg:flex-row gap-14">
                <div className="self-stretch flex flex-col gap-5 lg:max-w-[400px] lg:w-full">
                    <h3 className="heading-l self-center lg:self-start">{t("signUp.pricing")}</h3>
                    <div className="flex flex-col justify-center items-center sm:items-start lg:items-stretch gap-5 sm:flex-row lg:flex-col">
                        {offers.map(({id, ...props}) => (
                            <OfferComponent className="max-w-[400px] shrink w-full" key={id} {...props} />
                        ))}
                    </div>
                </div>
                <div className="flex flex-col gap-6 max-w-[480px] w-full">
                    <Form form={form} onSubmit={onSignUp} className="flex flex-col rounded-xl px-5 sm:px-10 py-8 bg-bg-300 shadow-offer">
                        <h3 className="heading-l text-center lg:text-left">{t("signUp.signUp")}</h3>
                        <div className="mt-6 lg:mt-9 mb-9 lg:mb-12 flex flex-col gap-6">
                            <EMailInput placeholder={t("input.email.placeholder")} options={{
                                required: t("input.email.required")
                            }} />
                            <Input type="password" name="password" label={t("signUp.password")} placeholder={t("input.password.placeholder")} options={{
                                required: t("input.password.required"),
                                minLength: {
                                    value: 8,
                                    message: t("input.password.minLength")
                                }
                            }} />
                        </div>
                        <Button variant="primary" submit>{t("signUp.submit")}</Button>
                        <p className="body-s text-center mt-6">
                            <Trans i18nKey="signUp.terms">
                                By clicking "Sign Up", you accept our <Link to={`/${lng}/tos`}>Terms of Service</Link> and
                                our <Link to={`/${lng}/privacy`}>Privacy policy</Link>
                            </Trans>
                        </p>
                    </Form>
                    <span className="body-l-md text-center">
                        <Trans i18nKey="signUp.toLogin">
                            Already a member? <Link to={redirect ? (() => {
                                const searchParams = new URLSearchParams;
                                searchParams.set("redirect", redirect);
                                return `/login?${searchParams.toString()}`;
                            })() : "/login"}>Login now</Link>
                        </Trans>
                    </span>
                </div>
            </div>
            <USPs className="self-center" />
        </div>
    )
}