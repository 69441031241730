import { now } from "@/js/common";
import { locales } from "@/js/types";
import bg from "@/svg/bg.svg";
import ChevronUp from "@/svg/chevron-up-regular.svg?react";
import CrownIcon from "@/svg/crown-duotone-solid.svg?react";
import { createRequiredContext } from "@enymo/react-better-context";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import Footer from "./Footer";
import Button from "./form/Button";
import Header from "./Header";
import UserDropdown from "./user/UserDropdown";
import UserMenu from "./user/UserMenu";
import YouthProtectionPopup from "./YouthProtectionPopup";

const [Provider, useContext] = createRequiredContext<(background: string | null) => void>("Layout must be in component tree");

export function useBackground(background: string | null) {
    const setDynamicBackground = useContext();

    useEffect(() => {
        setDynamicBackground(background);
        return () => setDynamicBackground(null);
    }, [background, setDynamicBackground]);
}

export default function Layout({background, user, noYouthProtection = false, localePath = false, onLogout, children}: {
    background: "default" | "account",
    user: "logout" | "login" | "member" | "admin",
    noYouthProtection?: boolean,
    localePath?: boolean,
    onLogout: () => void,
    children: React.ReactNode
}) {
    const {t, i18n} = useTranslation();
    const lng = i18n.language;
    const [youthProtectionAccepted, setYouthProtectionAccepted] = useState(() => localStorage.getItem("youthProtection") === "true");
    const [dynamicBackground, setDynamicBackground] = useState<string | null>(null);
    const [scrollTop, setScrollTop] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const handleScroll = () => {
            setScrollTop(document.documentElement.scrollTop);
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [setScrollTop]);

    const handleSearch = (search: string | null) => {
        if (search) {
            const searchParams = new URLSearchParams;
            searchParams.set("q", search);
            navigate(window.location.pathname.split("/")[2] === "search" ? `${window.location.pathname}?${searchParams.toString()}` : `/${lng}/search/videos?${searchParams.toString()}`);
        }
    }

    const handleAcceptYouthProtection = () => {
        localStorage.setItem("youthProtection", "true");
        setYouthProtectionAccepted(true);
    }

    const handleScrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const footer = (
        <Footer admin={user === "admin"} copyright={`© ${now.getFullYear()} Sextermedia`} items={[{
            to: `/${lng}/tos`,
            children: t("footer.tos")
        }, {
            to: `/${lng}/privacy`,
            children: t("footer.privacy")
        }, {
            to: `/${lng}/2257`,
            children: t("footer.youthProtection")
        }, {
            to: `/${lng}/report`,
            children: t("footer.report")
        }, {
            to: "https://epoch.com/billing_support",
            linkType: "new-tab",
            children: t("footer.billing")
        }, {
            to: `/${lng}/contact`,
            children: t("footer.contact")
        }, {
            to: `/${lng}/faq`,
            children: t("faq")
        }]} />
    );

    return (
        <div className="grow flex flex-col relative">
            {localePath && (
                <Helmet>
                    {locales.map(locale => (
                        <link key={locale} rel="alternate" hrefLang={locale} href={`${import.meta.env.VITE_APP_URL}/${locale}${location.pathname.substring(3)}`} />
                    ))}
                </Helmet>
            )}
            <img
                className={`absolute top-0 left-0 w-full object-cover -z-20 ${dynamicBackground ? "h-[680px]" : background === "default" ? "h-[500px]" : "h-full"}`}
                src={dynamicBackground ?? bg}
            />
            <div className={`absolute top-0 inset-x-0 -z-10 bg-gradient-to-b ${dynamicBackground ? "h-[800px] from-bg-900/75 to-bg-100/75 backdrop-blur-xl" : background === "default" ? "h-[600px] from-primary-200/85 to-bg-100/85 backdrop-blur-xl" : "h-full from-neutral-600/90 to-bg-300/90 backdrop-blur-2xl"}`} />
            <Header onChangeSearch={handleSearch} items={[{
                to: `/${lng}/videos`,
                children: t("header.videos")
            }, {
                to: `/${lng}/images`,
                children: t("header.images")
            }, {
                to: `/${lng}/categories`,
                children: t("header.categories")
            }, {
                to: `/${lng}/models`,
                children: t("header.models")
            }, {
                to: `/${lng}/coming-soon`,
                children: t("header.comingSoon")
            }]} primaryActions={
                <div className="flex items-center gap-3">
                    {user !== "member" && user !== "admin" && (
                        <Button to="/account/select-plan" className="hidden md:flex" innerClassName="flex gap-1.5 items-center" variant="member">
                            <CrownIcon className="w-4.5" />
                            {t("user.becomeMember")}
                        </Button>
                    )}
                    <UserDropdown>
                        <UserMenu localePath={localePath} isMember={user === "member" || user === "admin"} loggedIn={user !== "logout"} onLogout={onLogout} />
                    </UserDropdown>
                </div>
            } searchPlaceholder={t("search")} />
            <main className="grow flex flex-col">
                <Provider value={setDynamicBackground}>
                    {children}
                </Provider>
            </main>
            {footer}
            {!noYouthProtection && !youthProtectionAccepted && navigator.userAgent !== "SextermediaPrerender" && !navigator.userAgent.includes("Google") && (
                <YouthProtectionPopup footer={footer} onConfirm={handleAcceptYouthProtection} />
            )}
            {scrollTop > 400 && (
                <button onClick={handleScrollTop} className="fixed z-30 right-6 bottom-16 size-12 rounded-full flex justify-center items-center bg-primary-500 hover:bg-primary-400 shadow-floating">
                    <ChevronUp className="w-4 fill-primary-800" />
                </button>
            )}
        </div>
    )
}